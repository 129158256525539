import React, { useRef } from 'react';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import CustomButton from '../../../../components/General/CustomButton';
import useStyles from '../styles';
import GoogleMaps from './GoogleMaps';
import { ReduxState } from '../../../../types';
import { setFarmDevice, setFarmSector, setSensorNodeFilter } from '../../../../actions/farmSectorActions';
import { filteredDevices, handleKMZFile } from '../helpers';
import { PutSectorsKMZSchema } from '../../../../requests/api/apiTypes';

const LayoutMap = ({ setOpenUploadKMZModal, setKMZSector }: {
  setOpenUploadKMZModal: React.Dispatch<React.SetStateAction<{
    open: boolean, data: null | Array<PutSectorsKMZSchema[string]>
  }>>,
  setKMZSector: React.Dispatch<React.SetStateAction<null | PutSectorsKMZSchema[string]>>,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const KMZInputRef = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { selectedFarm } = useSelector((state: ReduxState) => state.accountFarm);
  const {
    selectedSector, allFarmDevices, selectedDevice, sensorNodeFilter,
  } = useSelector((state: ReduxState) => state.farmSector);

  const polygonsAux = selectedFarm?.sectors?.map((sector) => ({
    id: sector.id,
    coordinates: sector?.coordinates ?? [],
    isSelected: selectedSector === sector.id,
    onPolygonClick: () => dispatch(setFarmSector(sector.id ?? '')),
  })) ?? [];

  const markersAux = filteredDevices(allFarmDevices, sensorNodeFilter).map((device) => ({
    id: device.id,
    coordinates: device.coordinates,
    isSelected: device.id === selectedDevice,
    deviceType: device.deviceType,
    isGatewayNode: device.isGatewayNode,
    onMarkerClick: () => dispatch(setFarmDevice(device.id ?? '')),
  })) ?? [];

  const farmCoordinates = selectedFarm?.coordinates
    ? selectedFarm.coordinates.map(({ lat: farmLat, lng: farmLng }) => ({
      lat: typeof farmLat === 'string' ? parseFloat(farmLat) : farmLat,
      lng: typeof farmLng === 'string' ? parseFloat(farmLng) : farmLng,
    })) : null;

  const disabledLogic = () => {
    // KMZ can only be used on farms that have all sectors without coordinates
    if (!selectedFarm
      || selectedFarm?.sectors?.some((sector) => (sector?.coordinates?.length ?? 0) > 0)) {
      return true;
    }

    return false;
  };

  const handleKMZChange = async () => {
    const relevantFile = KMZInputRef?.current?.files?.[0];
    if (!relevantFile) {
      enqueueSnackbar('Archivo KMZ no pudo ser cargado correctamente', { variant: 'error' });
      return;
    }
    const validTypes = ['application/vnd.google-earth.kmz', '.kmz'];

    if (!validTypes.includes(relevantFile.type)) {
      enqueueSnackbar('Archivo cargado no es del tipo KMZ', { variant: 'error' });
      return;
    }

    try {
      const validPolygons = await handleKMZFile(relevantFile, setKMZSector);
      if (KMZInputRef?.current) KMZInputRef.current.value = '';
      setOpenUploadKMZModal({ open: true, data: validPolygons });
    } catch (err) {
      const error = err as Error;
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleAddKMZ = () => {
    if (KMZInputRef.current) {
      KMZInputRef.current.click();
    }
  };

  return (
    <Card className={classes.cardLayoutContainer}>
      <CardContent className={classes.cardLayoutContentContainer}>
        <Box className={classes.cardHeaderLayoutContentContainer}>
          <Box display='flex' alignItems='center'>
            <Box display='flex' alignItems='center' marginRight='20px'>
              <Typography
                variant="h4"
                color="primary"
              >
                Mapa
              </Typography>
            </Box>
            <Box>
              <CustomButton
                buttonText='subir kmz'
                onClick={handleAddKMZ} color='blue' size='small'
                /* () => setOpenUploadKMZModal(true) */
                disabled={disabledLogic()}
              />
              <input
                type="file"
                ref={KMZInputRef}
                style={{ display: 'none' }}
                accept=".kmz,application/vnd.google-earth.kmz"
                onChange={handleKMZChange}
              />
            </Box>
          </Box>
          <Box display='flex' paddingLeft='10px'>
            <Box display='flex' alignItems='center' flexWrap='wrap'>
              {sensorNodeFilter.deviceType === 'node'
                ? <>
                  <Chip key='gateway' color={sensorNodeFilter.filterValue === 'gateway' ? 'primary' : undefined} label="Gateway" onClick={() => dispatch(setSensorNodeFilter({ ...sensorNodeFilter, filterValue: 'gateway' }))} size='small' className={classes.layoutMapChip} />
                  <Chip key='noGateway' color={sensorNodeFilter.filterValue === 'noGateway' ? 'primary' : undefined} label="No Gateway" onClick={() => dispatch(setSensorNodeFilter({ ...sensorNodeFilter, filterValue: 'noGateway' }))} size='small' className={classes.layoutMapChip} />
                  <Chip key='todosNodo' color={sensorNodeFilter.filterValue === '' ? 'primary' : undefined} label="Todos" onClick={() => dispatch(setSensorNodeFilter({ ...sensorNodeFilter, filterValue: '' }))} size='small' className={classes.layoutMapChip} />
                </>
                : <>
                  <Chip key='suelo' color={sensorNodeFilter.filterValue === 'suelo' ? 'primary' : undefined} label="Suelo" onClick={() => dispatch(setSensorNodeFilter({ ...sensorNodeFilter, filterValue: 'suelo' }))} size='small' className={classes.layoutMapChip} />
                  <Chip key='clima' color={sensorNodeFilter.filterValue === 'clima' ? 'primary' : undefined} label="Clima" onClick={() => dispatch(setSensorNodeFilter({ ...sensorNodeFilter, filterValue: 'clima' }))} size='small' className={classes.layoutMapChip} />
                  <Chip key='planta' color={sensorNodeFilter.filterValue === 'planta' ? 'primary' : undefined} label="Planta" onClick={() => dispatch(setSensorNodeFilter({ ...sensorNodeFilter, filterValue: 'planta' }))} size='small' className={classes.layoutMapChip} />
                  <Chip key='riego' color={sensorNodeFilter.filterValue === 'riego' ? 'primary' : undefined} label="Riego" onClick={() => dispatch(setSensorNodeFilter({ ...sensorNodeFilter, filterValue: 'riego' }))} size='small' className={classes.layoutMapChip} />
                  <Chip key='todosSensor' color={sensorNodeFilter.filterValue === '' ? 'primary' : undefined} label="Todos" onClick={() => dispatch(setSensorNodeFilter({ ...sensorNodeFilter, filterValue: '' }))} size='small' className={classes.layoutMapChip} />
                </>
              }
            </Box>
            <Box>
              <Tabs
                value={sensorNodeFilter.deviceType}
                onChange={(e, value) => dispatch(setSensorNodeFilter({ deviceType: value, filterValue: '' }))}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab value="node" label="Nodos" />
                <Tab value="sensor" label="Sensores" />
              </Tabs>
            </Box>
          </Box>
        </Box>
        {selectedFarm
          && <Box className={classes.googleMapsContainer}>
            <GoogleMaps
              lat={selectedFarm.center.lat}
              lng={selectedFarm.center.lng}
              drawable='none'
              farm={{
                id: selectedFarm?.id ?? '',
                coordinates: farmCoordinates,
                zoom: selectedFarm?.zoom ?? 18,
              }}
              polygons={polygonsAux}
              markers={markersAux}
            />
          </Box>}
        {!selectedFarm && <Typography variant='body1'>
          Selecciona un campo para poder visualizar el mapa
        </Typography>}
      </CardContent>
    </Card>

  );
};

export default LayoutMap;
