import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  SvgIcon,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { v4 as uuidv4 } from 'uuid';

import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

import CustomTable from '../../../../../components/General/CustomTable';
import CustomButton from '../../../../../components/General/CustomButton';
import CardHeader from '../../../../../components/General/CardHeader';
import CustomModal from '../../../../../components/General/CustomModal';
import UpsertModalCultivationCycleContent from './UpsertCultivationCycleModalContent';
import useStyles from '../styles';
import { VarietySchema, CultivationCyclePopulatedSchema } from '../../../../../requests/api/apiTypes';
import apiClient from '../../../../../requests/api/apiClient';
import { FetchError } from '../../../../types';
import {
  FIELDS_EQ,
  SECTOR_LIST_UI,
  SimplifiedCultivationCycle,
  UpsertCultivationCycleInput,
} from '../../types';

function CultivationCyclesList({
  cultivationCycles, sectorId, varietiesList, setCultivationCyclesFunction,
}: {
  cultivationCycles: SimplifiedCultivationCycle[];
  sectorId: string;
  varietiesList: VarietySchema[];
  setCultivationCyclesFunction: React.Dispatch<
  React.SetStateAction<CultivationCyclePopulatedSchema[]>>;
}) {
  const classes = useStyles();
  const [upsertModal, setUpsertModal] = useState({ cycleId: '', open: false });
  const [localCultivationCycles, setLocalCultivationCycles] = useState<
  SimplifiedCultivationCycle[]>(cultivationCycles);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLocalCultivationCycles(cultivationCycles);
  }, [cultivationCycles]);

  // TO DO: Separarla en dos funciones mas
  const handleUpsertCultivationCycle = async (cultivationCycle: UpsertCultivationCycleInput) => {
    const hasMissingFields = Object.keys(cultivationCycle).some((key) => {
      const value = cultivationCycle[key as keyof UpsertCultivationCycleInput];
      if (!value) {
        enqueueSnackbar(`El campo ${FIELDS_EQ[key as keyof UpsertCultivationCycleInput]} debe ser completado`, { variant: 'error' });
        return true;
      }
      return false;
    });

    if (hasMissingFields) return;
    try {
      let response;

      if (upsertModal.cycleId) {
        response = await apiClient.cultivationCycle.cultivationCycleUpdate({
          id: upsertModal.cycleId,
          name: cultivationCycle.name,
          startDate: cultivationCycle.startDate,
          endDate: cultivationCycle.endDate,
          variety: cultivationCycle.varietyId,
        });

        const updatedCultivationCycle = response.data.updatedCycle;

        if (updatedCultivationCycle) {
          const variety = varietiesList.find((v) => v.id === updatedCultivationCycle.variety);

          setCultivationCyclesFunction((prevCycles) => prevCycles.map(
            (cycle) => (cycle.id === upsertModal.cycleId ? {
              id: updatedCultivationCycle.id,
              name: updatedCultivationCycle.name,
              phenologicalStates: updatedCultivationCycle.phenologicalStates,
              variety: {
                name: variety?.name || '',
                species: variety?.species || '',
              },
              startDate: new Date(updatedCultivationCycle.startDate).toISOString(),
              endDate: new Date(updatedCultivationCycle.endDate).toISOString(),
            } : cycle),
          ));
        }
      } else {
        response = await apiClient.cultivationCycle.cultivationCycleCreate(
          {
            name: cultivationCycle.name,
            phenologicalStates: [], // algo para añadir los default if needed
            variety: cultivationCycle.varietyId,
            sector: sectorId,
            startDate: cultivationCycle.startDate,
            endDate: cultivationCycle.endDate,
          },
        );
        const newCultivationCycle = response.data.cultivationCycle;

        if (newCultivationCycle) {
          const variety = varietiesList.find((v) => v.id === newCultivationCycle.variety);

          setCultivationCyclesFunction((prevCycles) => [...prevCycles, {
            id: newCultivationCycle.id,
            name: newCultivationCycle.name,
            phenologicalStates: [],
            variety: {
              name: variety?.name || '',
              species: variety?.species || '',
            },
            startDate: new Date(newCultivationCycle.startDate).toISOString(),
            endDate: new Date(newCultivationCycle.endDate).toISOString(),
          }]);
        }
      }

      setUpsertModal({ cycleId: '', open: false });
      enqueueSnackbar(response.data.message, { variant: 'success' });
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleEditCultivationCycle = (cycleId: string) => {
    const cycleToEdit = localCultivationCycles.find((cycle) => cycle.id === cycleId);
    if (cycleToEdit) {
      setUpsertModal({ cycleId, open: true });
    }
  };

  const handleRemoveCultivationCycle = async (id: string) => {
    try {
      const response = await apiClient.cultivationCycle.cultivationCycleDelete(id);
      setCultivationCyclesFunction((prevCycles) => prevCycles.filter((cycle) => cycle.id !== id));
      enqueueSnackbar(response.data.message, { variant: 'success' });
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const localData = {
    count: localCultivationCycles.length,
    values: localCultivationCycles.map((cycle) => ({
      id: uuidv4(),
      name: cycle.name,
      startingDate: new Date(cycle.startDate).toLocaleDateString('es-ES').replace(/\//g, '-'),
      endingDate: new Date(cycle.endDate).toLocaleDateString('es-ES').replace(/\//g, '-'),
      specie: cycle.varietySpecies,
      variety: cycle.varietyName,
      actions: (
        <>
          <IconButton onClick={() => handleEditCultivationCycle(cycle.id)}>
            <SvgIcon fontSize="small">
              <EditIcon />
            </SvgIcon>
          </IconButton>
          <IconButton size="large" onClick={() => handleRemoveCultivationCycle(cycle.id)}>
            <SvgIcon fontSize="small">
              <DeleteIcon />
            </SvgIcon>
          </IconButton>
        </>
      ),
    })),
  };

  return (
    <>
      <Box mt={3} mb={3}>
        <Card>
          <CardContent>
            <CardHeader
              title='Ciclo de cultivo'
              ActionButton={
                <CustomButton
                  buttonText='Agregar ciclo de cultivo'
                  color='blue'
                  size='small'
                  onClick={() => setUpsertModal({ cycleId: '', open: true })}
                />}
            />
            <Divider className={classes.divider} />
            <CustomTable
              rows={localData}
              headers={SECTOR_LIST_UI}
            />
          </CardContent>
        </Card>
      </Box>
      <CustomModal open={upsertModal.open} handleClose={() => setUpsertModal({ cycleId: '', open: false })} data={{ id: upsertModal.cycleId }}>
        <UpsertModalCultivationCycleContent
          handleCloseModal={() => setUpsertModal({ cycleId: '', open: false })}
          handleUpsertFunction={handleUpsertCultivationCycle}
          varietiesList={varietiesList}
          cycleId={upsertModal.cycleId}
          cultivationCycle={localCultivationCycles.find(
            (cycle) => cycle.id === upsertModal.cycleId,
          )}
        />
      </CustomModal>
    </>
  );
}

export default CultivationCyclesList;
