import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  Grid,
  Button,
} from '@mui/material';

import useStyles from '../styles';

function Header({ userToModify, handleSubmit }: {
  userToModify: string | undefined,
  handleSubmit: (userToModify: string | undefined) => void,
}) {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={3}
    >
      <Grid item>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          Usuarios / {userToModify
          ? <span className={classes.activePageUnderlined}>Editar usuario</span>
          : <span className={classes.activePageUnderlined}>Crear usuario</span>}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          onClick={() => handleSubmit(userToModify)}
          variant='contained'
          className={classes.greenButton}
        >
          Confirmar
        </Button>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
