import React from 'react';
import {
  Grid,
  Typography,
  Box,
} from '@mui/material';

import useStyles from './styles';

function CustomHeader({ title, ActionButton, TabsComponent }: {
  title: string,
  ActionButton?: JSX.Element,
  TabsComponent?: JSX.Element;
}) {
  const classes = useStyles();

  const parsedTitle = title.split('/').map((elem, index, arr) => {
    if (index === (arr.length - 1)) {
      return <React.Fragment key={index}>
        {' '}
        <span className={classes.activePageUnderlined}>
          {elem.trim()}
        </span>
      </React.Fragment>;
    }
    return (
      <React.Fragment key={index}>
        {elem.trim()} /
      </React.Fragment>
    );
  });

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={3}
      alignItems="center"
    >
      <Grid item>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          {parsedTitle}
        </Typography>
      </Grid>
      {ActionButton && <Grid item>
        {ActionButton}
      </Grid>}
      {TabsComponent && (
        <Grid item>
          <Box>
            {TabsComponent}
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

export default CustomHeader;
