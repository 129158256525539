import React, { useState } from 'react';
import {
  Box, Card, ClassNameMap, Container, IconButton,
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import Page from '../../components/Layout/Page';
import CustomSearch from '../../components/General/CustomSearch';
import CustomTable from '../../components/General/CustomTable';
import useStyles from './styles';
import CustomHeader from '../../components/General/CustomHeader';
import CustomButton from '../../components/General/CustomButton';
import CustomModal from '../../components/General/CustomModal';
import { GetVarietiesPerPageResponse, PostVarietySchema } from '../../requests/api/apiTypes';
import useFetchApi from '../../hooks/useFetchApi';
import CustomDeleteModalContent from '../../components/General/CustomDeleteModalContent';
import apiClient from '../../requests/api/apiClient';
import { FetchError } from '../types';
import UpsertModalVarietyContent from './components/UpsertModalVarietyContent';

const VARIETIES_LIST_UI = [
  {
    label: 'Especie', key: 'species',
  },
  {
    label: 'Nombre', key: 'name',
  },
  {
    label: 'Acciones', key: 'actions',
  }];

const twoButtonsAction = (
  classes: ClassNameMap<string>,
  accountId: string,
  setUpsertModal: ({ open, id }: { open: boolean, id: string }) => void,
  setOpenDeleteModal: ({ open, id }: { open: boolean, id: string }) => void,
) => (<Box display='flex' alignItems='center' justifyContent='center'>
  <IconButton onClick={() => setUpsertModal({ open: true, id: accountId })} >
    <EditIcon className={classes.icons} />
  </IconButton>
  <IconButton onClick={() => setOpenDeleteModal({ open: true, id: accountId })}>
    <DeleteIcon className={classes.icons} />
  </IconButton>
</Box>);

const VarietiesListView = () => {
  const classes = useStyles();
  const [paginationParameters, setPaginationParameters] = useState({
    page: 0,
    rowsPerPage: 5,
  });
  const [query, setQuery] = useState('');
  const [upsertModal, setUpsertModal] = useState({ id: '', open: false });
  const [openDeleteModal, setOpenDeleteModal] = useState({ id: '', open: false });
  const [updateData, setUpdateData] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { data: fetchData } = useFetchApi<GetVarietiesPerPageResponse>({
    modelName: 'varieties',
    modelEndpoint: 'varietiesList',
    dependencies: [
      paginationParameters.page, paginationParameters.rowsPerPage, query, updateData,
    ],
    inputData: {
      page: paginationParameters.page,
      limit: paginationParameters.rowsPerPage,
      searchValue: query,
    },
  });

  const varietiesHeadersUi = VARIETIES_LIST_UI.map((varietyUI) => {
    if (varietyUI.key === 'actions') {
      return {
        ...varietyUI,
        className: classes.tableHeaderStyle,
      };
    }
    return varietyUI;
  });

  const localData = {
    count: fetchData?.totalVarieties ?? 0,
    values: fetchData?.varieties?.map((elem) => ({
      id: elem.id ?? uuidv4(),
      name: elem.name ?? '',
      species: elem.species ?? 0,
      actions: twoButtonsAction(
        classes,
        elem.id as string,
        setUpsertModal,
        setOpenDeleteModal,
      ),
    })) ?? [],
  };

  const handleCloseModal = () => {
    setUpsertModal({ id: '', open: false });
  };

  const handleDeleteCloseModal = () => {
    setOpenDeleteModal({ id: '', open: false });
  };

  const handleQuery = (valueToSearch: string) => {
    setQuery(valueToSearch);
    setPaginationParameters((prevState) => ({ ...prevState, page: 0 }));
  };

  const handleDeleteFunction = async ({ id }: { id: string }) => {
    try {
      const { data } = await apiClient.varieties.varietiesDelete(id);

      setUpdateData((prevState) => !prevState);
      handleDeleteCloseModal();
      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleUpsertFunction = async ({
    name, species, phenologicalStates, id,
  }: {
    name: string, species: string, phenologicalStates: PostVarietySchema['phenologicalStates'], id?: string,
  }) => {
    try {
      let response;
      if (id) {
        // revisar especies - solo asociando NOMBRE, no la referencia
        response = await apiClient.varieties.varietiesUpdate(id, {
          name, species, phenologicalStates,
        });
      } else {
        response = await apiClient.varieties.varietiesCreate({
          name, species, phenologicalStates,
        });
      }

      setUpdateData((prevState) => !prevState);
      handleCloseModal();
      enqueueSnackbar(response.data.message, { variant: 'success' });
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <Page
      className={classes.root}
      title='Especies y variedades'
    >
      <Container maxWidth={false}>
        <CustomHeader
          title='Especies y variedades'
          ActionButton={
            <CustomButton
              buttonText='Crear variedad'
              color='blue'
              onClick={() => setUpsertModal({ id: '', open: true })}
            />}
        />
        <Box mt={3}>
          <Card>
            <CustomSearch
              query={query}
              handleQuery={handleQuery}
            />
            <CustomTable
              rows={localData}
              headers={varietiesHeadersUi}
              paginationParameters={paginationParameters}
              setPaginationParameters={setPaginationParameters}
            />
          </Card>
        </Box>
      </Container>
      <CustomModal open={upsertModal.open} handleClose={() => setUpsertModal({ id: '', open: false })} data={{ id: upsertModal.id }}>
        <UpsertModalVarietyContent
          handleCloseModal={handleCloseModal}
          handleUpsertFunction={handleUpsertFunction}
        />
      </CustomModal>
      <CustomModal open={openDeleteModal.open} handleClose={() => setOpenDeleteModal({ id: '', open: false })} data={{ id: openDeleteModal.id }}>
        <CustomDeleteModalContent
          title='Eliminar variedad'
          message='¿Estás seguro de querer eliminar esta variedad? No podras recuperar la información asociada a esta'
          handleCloseModal={handleDeleteCloseModal}
          handleDeleteFunction={handleDeleteFunction}
        />
      </CustomModal>
    </Page>
  );
};

export default VarietiesListView;
