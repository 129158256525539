import React from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  TextField,
} from '@mui/material';

import AuthService from '../../services/authService';
import { ClassNameProps, FetchError } from '../types';

// TODO: mejorar esto y probarlo
function RegisterForm({ className, ...rest }: ClassNameProps) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmitSuccess = () => {
    history.push('/postRegister');
  };

  return (
    <Formik
      initialValues={{
        name: '',
        lastname: '',
        email: '',
        password: '',

      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('nombre requerido'),
        lastname: Yup.string().max(255).required('apellido requerido'),
        email: Yup.string().max(255).required('Email requerido'),
        password: Yup.string().max(255).required('Contraseña requerida'),
      })}
      onSubmit={async (values, {
        setStatus,
        setSubmitting,
      }) => {
        try {
          const response = await
          AuthService.register(values.name, values.lastname, values.email);
          if (!response.success) {
            setStatus({ success: false });
            setSubmitting(false);
          } else {
            onSubmitSuccess();
          }
        } catch (err) {
          const { error } = err as FetchError;
          enqueueSnackbar(error.message, { variant: 'error' });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          className={clsx(className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            error={Boolean(touched.name && errors.name)}
            fullWidth
            color="secondary"
            helperText={touched.name && errors.name}
            label="Nombre"
            margin="normal"
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            type="name"
            value={values.name}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.lastname && errors.lastname)}
            fullWidth
            color="secondary"
            helperText={touched.lastname && errors.lastname}
            label="Apellido"
            margin="normal"
            name="lastname"
            onBlur={handleBlur}
            onChange={handleChange}
            type="lastname"
            value={values.lastname}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            color="secondary"
            helperText={touched.email && errors.email}
            label="Email"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            color="secondary"
            helperText={touched.password && errors.password}
            label="Contraseña"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Box mt={2}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Registrarse
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

export default RegisterForm;
