import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Typography,
} from '@mui/material';

import useStyles from '../styles';

function Header() {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      spacing={3}
    >
      <Grid item>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.activePageUnderlined}
        >
          Usuarios
        </Typography>
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          href="/usuarios/crear"
        >
          Crear usuario
        </Button>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
