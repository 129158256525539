/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CompanySchema {
  /** @example "669814726c9ca473fff3ee4d" */
  id?: string;
  /** @example "Empresa 123" */
  name: string;
  /** @example "12345678-9" */
  rut: string;
  /** @example "Empresa Cerezos" */
  businessName: string;
  /** @example "Chile" */
  country: string;
  /** @example "Region Metropolitana" */
  region: string;
  /** @example "Santiago" */
  commune: string;
  /** @example "123 Vespucio" */
  address: string;
  /** @example false */
  deleted: boolean;
  /**
   * @format date-time
   * @example "2023-03-07T19:43:46.303Z"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2023-03-07T19:43:46.303Z"
   */
  updatedAt: string;
}

export interface GetCompaniesPerPageResponse {
  /** @example true */
  success?: boolean;
  /** @example "Empresas encontradas exitosamente" */
  message?: string;
  companies?: {
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "Empresa 123" */
    name: string;
    /** @example "12345678-9" */
    rut: string;
    /** @example "Empresa Cerezos" */
    businessName: string;
    /** @example "Chile" */
    country: string;
    /** @example "Region Metropolitana" */
    region: string;
    /** @example "Santiago" */
    commune: string;
    /** @example "123 Vespucio" */
    address: string;
    /** @example false */
    deleted: boolean;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt: string;
  }[];
  /** @example 1 */
  totalCompanies?: number;
}

export interface GetCompanyByIdResponse {
  /** @example true */
  success?: boolean;
  /** @example "Empresa encontrada exitosamente" */
  message?: string;
  company?: {
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "Empresa 123" */
    name: string;
    /** @example "12345678-9" */
    rut: string;
    /** @example "Empresa Cerezos" */
    businessName: string;
    /** @example "Chile" */
    country: string;
    /** @example "Region Metropolitana" */
    region: string;
    /** @example "Santiago" */
    commune: string;
    /** @example "123 Vespucio" */
    address: string;
    /** @example false */
    deleted: boolean;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt: string;
  };
}

export interface PutCompaniesSoftlandToMongoResponse {
  /** @example true */
  success?: boolean;
  /** @example "Empresas actualizadas exitosamente" */
  message?: string;
}

export interface DeviceSchema {
  /** @example "669814726c9ca473fff3ee4d" */
  _id: string;
  deviceModel?: {
    /** @example "669814726c9ca473fff3ee4d" */
    _id: string;
    /** @example "669814726c9ca473fff3ee4d" */
    id: string;
    name: string;
    model?: string;
    group?: string;
    subGroup?: string;
    codProd?: string;
    /** @default false */
    deleted: boolean;
    /** @example "135654" */
    cdtecDeviceId?: string;
  };
  /** @example "669814726c9ca473fff3ee4d" */
  id?: string;
  /** @example "node" */
  deviceType: string;
  /** @example "1351-545-548-DP2564" */
  uniqueSoftlandId: string;
  /** @example "DP50256" */
  serie?: string;
  /** @example "135436" */
  nodeId: string;
  /** @example "suelo" */
  environmentalFactor?: string;
  /** @example true */
  isGatewayNode?: boolean;
  nodeChip?: string;
  /** @example "123456" */
  deviceId: string;
  /** @example "2024-05-01T00:00:00.000Z" */
  createdAt: string;
  /** @example "2024-05-01T00:00:00.000Z" */
  updatedAt: string;
  /** @example "active" */
  status: string;
  /** @example false */
  deleted: boolean;
  /** @example "Nuevo sensor" */
  comment?: string;
  /** @example "669814726c9ca473fff3ee4d" */
  farm: string;
  /** @example "669814726c9ca473fff3ee4d" */
  sector?: string;
  /** @example "669814726c9ca473fff3ee4d" */
  subSector?: string;
  coordinates?: {
    /**
     * @format float
     * @example -25.12
     */
    lng: number;
    /**
     * @format float
     * @example 35.12
     */
    lat: number;
  };
}

export interface DeviceModelSchema {
  /** @example "669814726c9ca473fff3ee4d" */
  _id: string;
  /** @example "669814726c9ca473fff3ee4d" */
  id: string;
  name: string;
  model?: string;
  group?: string;
  subGroup?: string;
  codProd?: string;
  /** @default false */
  deleted: boolean;
  /** @example "135654" */
  cdtecDeviceId?: string;
}

export interface PostSensorSchema {
  /** @example "node" */
  deviceType: string;
  /** @example "669814726c9ca473fff3ee4d" */
  farmId: string;
  /** @example "669814726c9ca473fff3ee4d" */
  sectorId?: string;
  /** @example "669814726c9ca473fff3ee4d" */
  subSectorId?: string;
  /** @example "669814726c9ca473fff3ee4d" */
  dispatchGuideId?: string;
  /** @example "Nuevo sensor" */
  comment?: string;
  coordinates: {
    /**
     * @format float
     * @example -25.12
     */
    lng: number;
    /**
     * @format float
     * @example 35.12
     */
    lat: number;
  };
  /** @example "135654" */
  cdtecDeviceId?: string;
  /** @example "669814726c9ca473fff3ee4d" */
  sensorType: string;
  /** @example "Sentek" */
  brand?: string;
  /** @example "Alpha" */
  model?: string;
  /** @example "669814726c9ca473fff3ee4d" */
  nodeId: string;
}

export interface PostNodeSchema {
  /** @example "node" */
  deviceType: string;
  /** @example "669814726c9ca473fff3ee4d" */
  farmId: string;
  /** @example "669814726c9ca473fff3ee4d" */
  sectorId?: string;
  /** @example "669814726c9ca473fff3ee4d" */
  subSectorId?: string;
  /** @example "669814726c9ca473fff3ee4d" */
  dispatchGuideId?: string;
  /** @example "Nuevo sensor" */
  comment?: string;
  coordinates: {
    /**
     * @format float
     * @example -25.12
     */
    lng: number;
    /**
     * @format float
     * @example 35.12
     */
    lat: number;
  };
  /** @example "135654" */
  cdtecDeviceId?: string;
  /** @example "669814726c9ca473fff3ee4d" */
  nodeType: string;
  /** @example "Sentek" */
  brand?: string;
  /** @example "Alpha" */
  model?: string;
  /** @example true */
  isGatewayNode?: boolean;
  nodeChip?: string;
}

export interface PostSensorDispatchGuideSchema {
  /** @example "node" */
  deviceType: string;
  /** @example "669814726c9ca473fff3ee4d" */
  farmId: string;
  /** @example "669814726c9ca473fff3ee4d" */
  sectorId?: string;
  /** @example "669814726c9ca473fff3ee4d" */
  subSectorId?: string;
  /** @example "669814726c9ca473fff3ee4d" */
  dispatchGuideId?: string;
  /** @example "Nuevo sensor" */
  comment?: string;
  coordinates: {
    /**
     * @format float
     * @example -25.12
     */
    lng: number;
    /**
     * @format float
     * @example 35.12
     */
    lat: number;
  };
  /** @example "669814726c9ca473fff3ee4d" */
  sensorId: string;
  /** @example "669814726c9ca473fff3ee4d" */
  nodeId: string;
}

export interface PostNodeDispatchGuideSchema {
  /** @example "node" */
  deviceType: string;
  /** @example "669814726c9ca473fff3ee4d" */
  farmId: string;
  /** @example "669814726c9ca473fff3ee4d" */
  sectorId?: string;
  /** @example "669814726c9ca473fff3ee4d" */
  subSectorId?: string;
  /** @example "669814726c9ca473fff3ee4d" */
  dispatchGuideId?: string;
  /** @example "Nuevo sensor" */
  comment?: string;
  coordinates: {
    /**
     * @format float
     * @example -25.12
     */
    lng: number;
    /**
     * @format float
     * @example 35.12
     */
    lat: number;
  };
  /** @example true */
  isGatewayNode?: boolean;
  nodeChip?: string;
  /** @example "669814726c9ca473fff3ee4d" */
  nodeId: string;
}

export interface PutDeviceSchema {
  /** @example "Updated Device" */
  deviceName?: string;
  /** @example "active" */
  status: string;
  /** @example false */
  deleted?: boolean;
  /** @example "Nuevo sensor" */
  comment?: string;
}

export interface GetDevicesPerPageSchema {
  /**
   * @format float
   * @example 10
   */
  limit?: number;
  /**
   * @format float
   * @example 0
   */
  page?: number;
  /** @example "" */
  searchValue?: string;
  /** @pattern (\|DESC\b|\|ASC\b) */
  sort?: string;
  /** @example "" */
  accountId?: string | null;
}

export interface GetDevicesByFarmIdSchema {
  /** @example "669814726c9ca473fff3ee4d" */
  farmId: string;
}

export interface GetDevicesPerPageResponse {
  /** @example true */
  success?: boolean;
  /** @example "Equipos encontrados exitosamente" */
  message?: string;
  devices?: {
    /** @example "669814726c9ca473fff3ee4d" */
    _id: string;
    deviceModel?: {
      /** @example "669814726c9ca473fff3ee4d" */
      _id: string;
      /** @example "669814726c9ca473fff3ee4d" */
      id: string;
      name: string;
      model?: string;
      group?: string;
      subGroup?: string;
      codProd?: string;
      /** @default false */
      deleted: boolean;
      /** @example "135654" */
      cdtecDeviceId?: string;
    };
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "node" */
    deviceType: string;
    /** @example "1351-545-548-DP2564" */
    uniqueSoftlandId: string;
    /** @example "DP50256" */
    serie?: string;
    /** @example "135436" */
    nodeId: string;
    /** @example "suelo" */
    environmentalFactor?: string;
    /** @example true */
    isGatewayNode?: boolean;
    nodeChip?: string;
    /** @example "123456" */
    deviceId: string;
    /** @example "2024-05-01T00:00:00.000Z" */
    createdAt: string;
    /** @example "2024-05-01T00:00:00.000Z" */
    updatedAt: string;
    /** @example "active" */
    status: string;
    /** @example false */
    deleted: boolean;
    /** @example "Nuevo sensor" */
    comment?: string;
    /** @example "669814726c9ca473fff3ee4d" */
    farm: string;
    /** @example "669814726c9ca473fff3ee4d" */
    sector?: string;
    /** @example "669814726c9ca473fff3ee4d" */
    subSector?: string;
    coordinates?: {
      /**
       * @format float
       * @example -25.12
       */
      lng: number;
      /**
       * @format float
       * @example 35.12
       */
      lat: number;
    };
  }[];
  /** @example 1 */
  totalDevices?: number;
}

export interface GetDeviceByIdResponse {
  /** @example true */
  success?: boolean;
  /** @example "Equipo encontrado exitosamente" */
  message?: string;
  device?: {
    /** @example "669814726c9ca473fff3ee4d" */
    _id: string;
    deviceModel?: {
      /** @example "669814726c9ca473fff3ee4d" */
      _id: string;
      /** @example "669814726c9ca473fff3ee4d" */
      id: string;
      name: string;
      model?: string;
      group?: string;
      subGroup?: string;
      codProd?: string;
      /** @default false */
      deleted: boolean;
      /** @example "135654" */
      cdtecDeviceId?: string;
    };
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "node" */
    deviceType: string;
    /** @example "1351-545-548-DP2564" */
    uniqueSoftlandId: string;
    /** @example "DP50256" */
    serie?: string;
    /** @example "135436" */
    nodeId: string;
    /** @example "suelo" */
    environmentalFactor?: string;
    /** @example true */
    isGatewayNode?: boolean;
    nodeChip?: string;
    /** @example "123456" */
    deviceId: string;
    /** @example "2024-05-01T00:00:00.000Z" */
    createdAt: string;
    /** @example "2024-05-01T00:00:00.000Z" */
    updatedAt: string;
    /** @example "active" */
    status: string;
    /** @example false */
    deleted: boolean;
    /** @example "Nuevo sensor" */
    comment?: string;
    /** @example "669814726c9ca473fff3ee4d" */
    farm: string;
    /** @example "669814726c9ca473fff3ee4d" */
    sector?: string;
    /** @example "669814726c9ca473fff3ee4d" */
    subSector?: string;
    coordinates?: {
      /**
       * @format float
       * @example -25.12
       */
      lng: number;
      /**
       * @format float
       * @example 35.12
       */
      lat: number;
    };
  };
}

export interface PutDevicesSoftlandToMongoResponse {
  /** @example true */
  success?: boolean;
  /** @example "Equipos actualizados exitosamente" */
  message?: string;
}

export interface PostDevicesResponse {
  /** @example true */
  success?: boolean;
  /** @example "Equipos actualizados exitosamente" */
  message?: string;
  device?: {
    id?: string;
    type: string;
    brand: string;
    model: string;
    nodeId?: string | null;
    nodeType?: string | null;
  };
}

export interface PutDeviceResponse {
  /** @example true */
  success?: boolean;
  /** @example "Equipo actualizado exitosamente" */
  message?: string;
  device?: {
    /** @example "669814726c9ca473fff3ee4d" */
    _id: string;
    deviceModel?: {
      /** @example "669814726c9ca473fff3ee4d" */
      _id: string;
      /** @example "669814726c9ca473fff3ee4d" */
      id: string;
      name: string;
      model?: string;
      group?: string;
      subGroup?: string;
      codProd?: string;
      /** @default false */
      deleted: boolean;
      /** @example "135654" */
      cdtecDeviceId?: string;
    };
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "node" */
    deviceType: string;
    /** @example "1351-545-548-DP2564" */
    uniqueSoftlandId: string;
    /** @example "DP50256" */
    serie?: string;
    /** @example "135436" */
    nodeId: string;
    /** @example "suelo" */
    environmentalFactor?: string;
    /** @example true */
    isGatewayNode?: boolean;
    nodeChip?: string;
    /** @example "123456" */
    deviceId: string;
    /** @example "2024-05-01T00:00:00.000Z" */
    createdAt: string;
    /** @example "2024-05-01T00:00:00.000Z" */
    updatedAt: string;
    /** @example "active" */
    status: string;
    /** @example false */
    deleted: boolean;
    /** @example "Nuevo sensor" */
    comment?: string;
    /** @example "669814726c9ca473fff3ee4d" */
    farm: string;
    /** @example "669814726c9ca473fff3ee4d" */
    sector?: string;
    /** @example "669814726c9ca473fff3ee4d" */
    subSector?: string;
    coordinates?: {
      /**
       * @format float
       * @example -25.12
       */
      lng: number;
      /**
       * @format float
       * @example 35.12
       */
      lat: number;
    };
  };
}

export interface GetDevicesByFarmIdResponse {
  /** @example true */
  success?: boolean;
  /** @example "Equipos encontrados exitosamente" */
  message?: string;
  devices?: {
    /** @example "669814726c9ca473fff3ee4d" */
    _id: string;
    deviceModel?: {
      /** @example "669814726c9ca473fff3ee4d" */
      _id: string;
      /** @example "669814726c9ca473fff3ee4d" */
      id: string;
      name: string;
      model?: string;
      group?: string;
      subGroup?: string;
      codProd?: string;
      /** @default false */
      deleted: boolean;
      /** @example "135654" */
      cdtecDeviceId?: string;
    };
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "node" */
    deviceType: string;
    /** @example "1351-545-548-DP2564" */
    uniqueSoftlandId: string;
    /** @example "DP50256" */
    serie?: string;
    /** @example "135436" */
    nodeId: string;
    /** @example "suelo" */
    environmentalFactor?: string;
    /** @example true */
    isGatewayNode?: boolean;
    nodeChip?: string;
    /** @example "123456" */
    deviceId: string;
    /** @example "2024-05-01T00:00:00.000Z" */
    createdAt: string;
    /** @example "2024-05-01T00:00:00.000Z" */
    updatedAt: string;
    /** @example "active" */
    status: string;
    /** @example false */
    deleted: boolean;
    /** @example "Nuevo sensor" */
    comment?: string;
    /** @example "669814726c9ca473fff3ee4d" */
    farm: string;
    /** @example "669814726c9ca473fff3ee4d" */
    sector?: string;
    /** @example "669814726c9ca473fff3ee4d" */
    subSector?: string;
    coordinates?: {
      /**
       * @format float
       * @example -25.12
       */
      lng: number;
      /**
       * @format float
       * @example 35.12
       */
      lat: number;
    };
  }[];
}

export interface SectorSchema {
  /** @example "669814726c9ca473fff3ee4d" */
  id?: string;
  /** @example "Sector 1" */
  name: string;
  /** @example "1234568" */
  cdtecSectorId: string;
  /** @example "100" */
  theoreticalSurface: string;
  /** @example "669814726c9ca473fff3ee4d" */
  farmId?: string;
  /** @example "5618131" */
  wiseconnId?: string;
  /** @example "Loamy" */
  typeOfSoil: string;
  coordinates?: {
    /**
     * @format float
     * @example -25.12
     */
    lng: number;
    /**
     * @format float
     * @example 35.12
     */
    lat: number;
  }[];
  /** @example [] */
  subSectors?: any[];
}

export interface SectorDetailSchema {
  /** @example "669814726c9ca473fff3ee4d" */
  id?: string;
  /** @example "Sector 1" */
  name: string;
  /** @example "1234568" */
  cdtecSectorId: string;
  /** @example "100" */
  theoreticalSurface: string;
  /** @example "669814726c9ca473fff3ee4d" */
  farmId?: string;
  /** @example "5618131" */
  wiseconnId?: string;
  /** @example "Loamy" */
  typeOfSoil: string;
  coordinates?: {
    /**
     * @format float
     * @example -25.12
     */
    lng: number;
    /**
     * @format float
     * @example 35.12
     */
    lat: number;
  }[];
  /** @example [] */
  subSectors?: any[];
  /** @example "pluvial" */
  typeOfIrrigation?: string;
  /** @example "Chandler" */
  variety?: string;
  /**
   * @format float
   * @example 2020
   */
  plantingYear?: number;
  /** @example "Normal" */
  emitterFlowRate?: string;
  /**
   * @format float
   * @example 100
   */
  transmittersDistance?: number;
  /** @example "Regular" */
  plantingFrame?: string;
  /** @example "Patricio Orrego" */
  managerName?: string;
  /** @example "Sector padre" */
  parentSector?: string;
}

export interface GetSectorsPerPageSchema {
  /**
   * @format float
   * @example 10
   */
  limit?: number;
  /**
   * @format float
   * @example 0
   */
  page?: number;
  /** @example "" */
  searchValue?: string;
  /** @pattern (\|DESC\b|\|ASC\b) */
  sort?: string;
}

export interface PostFarmSectorsSchema {
  /** @example "669814726c9ca473fff3ee4d" */
  id?: string;
  /** @example "Sector 1" */
  name: string;
  /** @example "1234568" */
  cdtecSectorId: string;
  /** @example "100" */
  theoreticalSurface: string;
  /** @example "669814726c9ca473fff3ee4d" */
  farmId?: string;
  /** @example "5618131" */
  wiseconnId?: string;
  /** @example "Loamy" */
  typeOfSoil: string;
  coordinates?: {
    /**
     * @format float
     * @example -25.12
     */
    lng: number;
    /**
     * @format float
     * @example 35.12
     */
    lat: number;
  }[];
  /** @example [] */
  subSectors?: any[];
}

export interface UpdateSectorSchema {
  /** @example "Sector 1" */
  name: string;
  /** @example "100" */
  theoreticalSurface: string;
  /** @example "pluvial" */
  typeOfIrrigation?: string;
  /** @example "Loamy" */
  typeOfSoil: string;
  /** @example "Chandler" */
  variety?: string;
  /**
   * @format float
   * @example 2020
   */
  plantingYear?: number;
  /** @example "Normal" */
  emitterFlowRate?: string;
  /**
   * @format float
   * @example 100
   */
  transmittersDistance?: number;
  /** @example "Regular" */
  plantingFrame?: string;
  /** @example "Patricio Orrego" */
  managerName?: string;
  /** @example "Sector padre" */
  parentSector?: string | null;
}

export type PutSectorsKMZSchema = Record<
  string,
  {
    /** @format float */
    id: string;
    coordinates: {
      /** @format float */
      lat: number;
      /** @format float */
      lng: number;
    }[];
  }
>;

export interface PostFarmSectorsResponse {
  /** @example true */
  success?: boolean;
  /** @example "Sector anadido a campo exitosamente" */
  message?: string;
  sector?: {
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "Sector 1" */
    name: string;
    /** @example "1234568" */
    cdtecSectorId: string;
    /** @example "100" */
    theoreticalSurface: string;
    /** @example "669814726c9ca473fff3ee4d" */
    farmId?: string;
    /** @example "5618131" */
    wiseconnId?: string;
    /** @example "Loamy" */
    typeOfSoil: string;
    coordinates?: {
      /**
       * @format float
       * @example -25.12
       */
      lng: number;
      /**
       * @format float
       * @example 35.12
       */
      lat: number;
    }[];
    /** @example [] */
    subSectors?: any[];
  };
}

export interface SectorByIdSchemaResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuario encontrado" */
  message?: string;
  sector?: {
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "Sector 1" */
    name: string;
    /** @example "1234568" */
    cdtecSectorId: string;
    /** @example "100" */
    theoreticalSurface: string;
    /** @example "669814726c9ca473fff3ee4d" */
    farmId?: string;
    /** @example "5618131" */
    wiseconnId?: string;
    /** @example "Loamy" */
    typeOfSoil: string;
    coordinates?: {
      /**
       * @format float
       * @example -25.12
       */
      lng: number;
      /**
       * @format float
       * @example 35.12
       */
      lat: number;
    }[];
    /** @example [] */
    subSectors?: any[];
    /** @example "pluvial" */
    typeOfIrrigation?: string;
    /** @example "Chandler" */
    variety?: string;
    /**
     * @format float
     * @example 2020
     */
    plantingYear?: number;
    /** @example "Normal" */
    emitterFlowRate?: string;
    /**
     * @format float
     * @example 100
     */
    transmittersDistance?: number;
    /** @example "Regular" */
    plantingFrame?: string;
    /** @example "Patricio Orrego" */
    managerName?: string;
    /** @example "Sector padre" */
    parentSector?: string;
  };
}

export interface UpdateSectorResponse {
  /** @example true */
  success?: boolean;
  /** @example "Sector editado exitosamente" */
  message?: string;
}

export interface PutSectorsKMZResponse {
  /** @example true */
  success?: boolean;
  /** @example "Sectores actualizados basados en KMZ" */
  message?: string;
  sectors?: {
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "Sector 1" */
    name: string;
    /** @example "1234568" */
    cdtecSectorId: string;
    /** @example "100" */
    theoreticalSurface: string;
    /** @example "669814726c9ca473fff3ee4d" */
    farmId?: string;
    /** @example "5618131" */
    wiseconnId?: string;
    /** @example "Loamy" */
    typeOfSoil: string;
    coordinates?: {
      /**
       * @format float
       * @example -25.12
       */
      lng: number;
      /**
       * @format float
       * @example 35.12
       */
      lat: number;
    }[];
    /** @example [] */
    subSectors?: any[];
  }[];
}

export interface FarmSchema {
  /** @example "Campo Cerezos" */
  name: string;
  /** @example "Calle Ejemplo Exampl 123" */
  location: string;
  /** @example "100" */
  theoreticalSurface: string;
  center: {
    /** @example "-30.35161" */
    lat: string | number;
    /** @example "58.681618" */
    lng: string | number;
  };
  coordinates?: {
    /** @example "-30.35161" */
    lat: string;
    /** @example "58.681618" */
    lng: string;
  }[];
  /**
   * @format float
   * @example 18
   */
  zoom?: number;
  /** @example "669814726c9ca473fff3ee4d" */
  accountId?: string;
  sectors?: {
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "Sector 1" */
    name: string;
    /** @example "1234568" */
    cdtecSectorId: string;
    /** @example "100" */
    theoreticalSurface: string;
    /** @example "669814726c9ca473fff3ee4d" */
    farmId?: string;
    /** @example "5618131" */
    wiseconnId?: string;
    /** @example "Loamy" */
    typeOfSoil: string;
    coordinates?: {
      /**
       * @format float
       * @example -25.12
       */
      lng: number;
      /**
       * @format float
       * @example 35.12
       */
      lat: number;
    }[];
    /** @example [] */
    subSectors?: any[];
  }[];
  devices?: {
    id?: string;
    type: string;
    brand: string;
    model: string;
    nodeId?: string | null;
    nodeType?: string | null;
  }[];
  /** @example "58" */
  wiseconnId?: string;
  /** @example "669814726c9ca473fff3ee4d" */
  id?: string;
  /** @default false */
  deleted?: boolean;
  /**
   * @format date-time
   * @default "2023-03-07T19:43:46.303Z"
   */
  createdAt?: string;
  /**
   * @format date-time
   * @default "2023-03-07T19:43:46.303Z"
   */
  updatedAt?: string;
}

export interface PostFarmSchema {
  /** @example "Campo Cerezos" */
  name: string;
  /** @example "Calle Ejemplo Exampl 123" */
  location: string;
  /** @example "100" */
  theoreticalSurface: string;
  center: {
    /** @example "-30.35161" */
    lat: string | number;
    /** @example "58.681618" */
    lng: string | number;
  };
  coordinates?: {
    /**
     * @format float
     * @example -30.35161
     */
    lat: number;
    /**
     * @format float
     * @example 58.681618
     */
    lng: number;
  }[];
  /**
   * @format float
   * @example 18
   */
  zoom?: number;
  /** @example "669814726c9ca473fff3ee4d" */
  accountId?: string;
  sectors?: {
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "Sector 1" */
    name: string;
    /** @example "1234568" */
    cdtecSectorId: string;
    /** @example "100" */
    theoreticalSurface: string;
    /** @example "669814726c9ca473fff3ee4d" */
    farmId?: string;
    /** @example "5618131" */
    wiseconnId?: string;
    /** @example "Loamy" */
    typeOfSoil: string;
    coordinates?: {
      /**
       * @format float
       * @example -25.12
       */
      lng: number;
      /**
       * @format float
       * @example 35.12
       */
      lat: number;
    }[];
    /** @example [] */
    subSectors?: any[];
  }[];
  devices?: {
    id?: string;
    type: string;
    brand: string;
    model: string;
    nodeId?: string | null;
    nodeType?: string | null;
  }[];
  /** @example "58" */
  wiseconnId?: string;
  /** @example "669814726c9ca473fff3ee4d" */
  accountDocumentId?: string;
}

export interface GetFarmsPerPageSchema {
  /**
   * @format float
   * @example 10
   */
  limit?: number;
  /**
   * @format float
   * @example 0
   */
  page?: number;
  /** @example "" */
  searchValue?: string;
  /** @pattern (\|DESC\b|\|ASC\b) */
  sort?: string;
}

export interface GetFarmsPerPageResponse {
  /** @example true */
  success?: boolean;
  /** @example "Cuenta creada exitosamente" */
  message?: string;
  farms?: {
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "New Far" */
    name?: string;
  }[];
  /** @example 1 */
  totalFarms?: number;
}

export interface PostFarmsResponse {
  /** @example true */
  success?: boolean;
  /** @example "Campo creado exitosamente" */
  message?: string;
  farm?: {
    /** @example "Campo Cerezos" */
    name: string;
    /** @example "Calle Ejemplo Exampl 123" */
    location: string;
    /** @example "100" */
    theoreticalSurface: string;
    center: {
      /** @example "-30.35161" */
      lat: string | number;
      /** @example "58.681618" */
      lng: string | number;
    };
    coordinates?: {
      /** @example "-30.35161" */
      lat: string;
      /** @example "58.681618" */
      lng: string;
    }[];
    /**
     * @format float
     * @example 18
     */
    zoom?: number;
    /** @example "669814726c9ca473fff3ee4d" */
    accountId?: string;
    sectors?: {
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @example "Sector 1" */
      name: string;
      /** @example "1234568" */
      cdtecSectorId: string;
      /** @example "100" */
      theoreticalSurface: string;
      /** @example "669814726c9ca473fff3ee4d" */
      farmId?: string;
      /** @example "5618131" */
      wiseconnId?: string;
      /** @example "Loamy" */
      typeOfSoil: string;
      coordinates?: {
        /**
         * @format float
         * @example -25.12
         */
        lng: number;
        /**
         * @format float
         * @example 35.12
         */
        lat: number;
      }[];
      /** @example [] */
      subSectors?: any[];
    }[];
    devices?: {
      id?: string;
      type: string;
      brand: string;
      model: string;
      nodeId?: string | null;
      nodeType?: string | null;
    }[];
    /** @example "58" */
    wiseconnId?: string;
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @default false */
    deleted?: boolean;
    /**
     * @format date-time
     * @default "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @default "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
  };
}

export interface GetFarmsByAccountIdResponse {
  /** @example true */
  success?: boolean;
  /** @example "Campos asociados a la cuenta encontrados exitosamente" */
  message?: string;
  allFarms?: {
    /** @example "Campo Cerezos" */
    name: string;
    /** @example "Calle Ejemplo Exampl 123" */
    location: string;
    /** @example "100" */
    theoreticalSurface: string;
    center: {
      /** @example "-30.35161" */
      lat: string | number;
      /** @example "58.681618" */
      lng: string | number;
    };
    coordinates?: {
      /** @example "-30.35161" */
      lat: string;
      /** @example "58.681618" */
      lng: string;
    }[];
    /**
     * @format float
     * @example 18
     */
    zoom?: number;
    /** @example "669814726c9ca473fff3ee4d" */
    accountId?: string;
    sectors?: {
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @example "Sector 1" */
      name: string;
      /** @example "1234568" */
      cdtecSectorId: string;
      /** @example "100" */
      theoreticalSurface: string;
      /** @example "669814726c9ca473fff3ee4d" */
      farmId?: string;
      /** @example "5618131" */
      wiseconnId?: string;
      /** @example "Loamy" */
      typeOfSoil: string;
      coordinates?: {
        /**
         * @format float
         * @example -25.12
         */
        lng: number;
        /**
         * @format float
         * @example 35.12
         */
        lat: number;
      }[];
      /** @example [] */
      subSectors?: any[];
    }[];
    devices?: {
      id?: string;
      type: string;
      brand: string;
      model: string;
      nodeId?: string | null;
      nodeType?: string | null;
    }[];
    /** @example "58" */
    wiseconnId?: string;
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @default false */
    deleted?: boolean;
    /**
     * @format date-time
     * @default "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @default "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
  }[];
}

export interface AccountSchema {
  /** @example "669814726c9ca473fff3ee4d" */
  _id?: string;
  /** @example "Tubesoft" */
  name: string;
  /** @example "669814726c9ca473fff3ee4d" */
  id?: string;
  companies?: {
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "Empresa 123" */
    name: string;
    /** @example "12345678-9" */
    rut: string;
    /** @example "Empresa Cerezos" */
    businessName: string;
    /** @example "Chile" */
    country: string;
    /** @example "Region Metropolitana" */
    region: string;
    /** @example "Santiago" */
    commune: string;
    /** @example "123 Vespucio" */
    address: string;
    /** @example false */
    deleted: boolean;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt: string;
  }[];
  farms?: {
    /** @example "Campo Cerezos" */
    name: string;
    /** @example "Calle Ejemplo Exampl 123" */
    location: string;
    /** @example "100" */
    theoreticalSurface: string;
    center: {
      /** @example "-30.35161" */
      lat: string | number;
      /** @example "58.681618" */
      lng: string | number;
    };
    coordinates?: {
      /** @example "-30.35161" */
      lat: string;
      /** @example "58.681618" */
      lng: string;
    }[];
    /**
     * @format float
     * @example 18
     */
    zoom?: number;
    /** @example "669814726c9ca473fff3ee4d" */
    accountId?: string;
    sectors?: {
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @example "Sector 1" */
      name: string;
      /** @example "1234568" */
      cdtecSectorId: string;
      /** @example "100" */
      theoreticalSurface: string;
      /** @example "669814726c9ca473fff3ee4d" */
      farmId?: string;
      /** @example "5618131" */
      wiseconnId?: string;
      /** @example "Loamy" */
      typeOfSoil: string;
      coordinates?: {
        /**
         * @format float
         * @example -25.12
         */
        lng: number;
        /**
         * @format float
         * @example 35.12
         */
        lat: number;
      }[];
      /** @example [] */
      subSectors?: any[];
    }[];
    devices?: {
      id?: string;
      type: string;
      brand: string;
      model: string;
      nodeId?: string | null;
      nodeType?: string | null;
    }[];
    /** @example "58" */
    wiseconnId?: string;
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @default false */
    deleted?: boolean;
    /**
     * @format date-time
     * @default "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @default "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
  }[];
}

export interface GetAccountsPerPageSchema {
  /**
   * @format float
   * @example 10
   */
  limit?: number;
  /**
   * @format float
   * @example 0
   */
  page?: number;
  /** @example "" */
  searchValue?: string;
  /** @pattern (\|DESC\b|\|ASC\b) */
  sort?: string;
}

export interface PostAccountSchema {
  /** @example "669814726c9ca473fff3ee4d" */
  _id?: string;
  /** @example "Tubesoft" */
  name: string;
  companies?: string[];
}

export interface PutAccountSchema {
  /** @example "669814726c9ca473fff3ee4d" */
  _id?: string;
  /** @example "Tubesoft" */
  name: string;
  companies?: string[];
}

export interface DeleteAccountSchema {
  /** @example "669814726c9ca473fff3ee4d" */
  id: string;
}

export interface GetAccountsPerPageResponse {
  /** @example true */
  success?: boolean;
  /** @example "Cuenta creada exitosamente" */
  message?: string;
  accounts?: {
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "New Company" */
    name?: string;
    companies?: {
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @example "Empresa 123" */
      name: string;
      /** @example "12345678-9" */
      rut: string;
      /** @example "Empresa Cerezos" */
      businessName: string;
      /** @example "Chile" */
      country: string;
      /** @example "Region Metropolitana" */
      region: string;
      /** @example "Santiago" */
      commune: string;
      /** @example "123 Vespucio" */
      address: string;
      /** @example false */
      deleted: boolean;
      /**
       * @format date-time
       * @example "2023-03-07T19:43:46.303Z"
       */
      createdAt: string;
      /**
       * @format date-time
       * @example "2023-03-07T19:43:46.303Z"
       */
      updatedAt: string;
    };
    farms?: {
      /** @example "Campo Cerezos" */
      name: string;
      /** @example "Calle Ejemplo Exampl 123" */
      location: string;
      /** @example "100" */
      theoreticalSurface: string;
      center: {
        /** @example "-30.35161" */
        lat: string | number;
        /** @example "58.681618" */
        lng: string | number;
      };
      coordinates?: {
        /** @example "-30.35161" */
        lat: string;
        /** @example "58.681618" */
        lng: string;
      }[];
      /**
       * @format float
       * @example 18
       */
      zoom?: number;
      /** @example "669814726c9ca473fff3ee4d" */
      accountId?: string;
      sectors?: {
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @example "Sector 1" */
        name: string;
        /** @example "1234568" */
        cdtecSectorId: string;
        /** @example "100" */
        theoreticalSurface: string;
        /** @example "669814726c9ca473fff3ee4d" */
        farmId?: string;
        /** @example "5618131" */
        wiseconnId?: string;
        /** @example "Loamy" */
        typeOfSoil: string;
        coordinates?: {
          /**
           * @format float
           * @example -25.12
           */
          lng: number;
          /**
           * @format float
           * @example 35.12
           */
          lat: number;
        }[];
        /** @example [] */
        subSectors?: any[];
      }[];
      devices?: {
        id?: string;
        type: string;
        brand: string;
        model: string;
        nodeId?: string | null;
        nodeType?: string | null;
      }[];
      /** @example "58" */
      wiseconnId?: string;
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @default false */
      deleted?: boolean;
      /**
       * @format date-time
       * @default "2023-03-07T19:43:46.303Z"
       */
      createdAt?: string;
      /**
       * @format date-time
       * @default "2023-03-07T19:43:46.303Z"
       */
      updatedAt?: string;
    }[];
    /** @example 1 */
    numberOfCompanies?: number;
    /** @example 3 */
    numberOfFarms?: number;
  }[];
  /** @example 1 */
  totalAccounts?: number;
}

export interface PostAccountResponse {
  /** @example true */
  success?: boolean;
  /** @example "Cuenta creada exitosamente" */
  message?: string;
}

export interface GetAccountByIdResponse {
  /** @example true */
  success?: boolean;
  /** @example "Cuenta encontrada exitosamente" */
  message?: string;
  account?: {
    /** @example "669814726c9ca473fff3ee4d" */
    _id?: string;
    /** @example "Tubesoft" */
    name: string;
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    companies?: {
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @example "Empresa 123" */
      name: string;
      /** @example "12345678-9" */
      rut: string;
      /** @example "Empresa Cerezos" */
      businessName: string;
      /** @example "Chile" */
      country: string;
      /** @example "Region Metropolitana" */
      region: string;
      /** @example "Santiago" */
      commune: string;
      /** @example "123 Vespucio" */
      address: string;
      /** @example false */
      deleted: boolean;
      /**
       * @format date-time
       * @example "2023-03-07T19:43:46.303Z"
       */
      createdAt: string;
      /**
       * @format date-time
       * @example "2023-03-07T19:43:46.303Z"
       */
      updatedAt: string;
    }[];
    farms?: {
      /** @example "Campo Cerezos" */
      name: string;
      /** @example "Calle Ejemplo Exampl 123" */
      location: string;
      /** @example "100" */
      theoreticalSurface: string;
      center: {
        /** @example "-30.35161" */
        lat: string | number;
        /** @example "58.681618" */
        lng: string | number;
      };
      coordinates?: {
        /** @example "-30.35161" */
        lat: string;
        /** @example "58.681618" */
        lng: string;
      }[];
      /**
       * @format float
       * @example 18
       */
      zoom?: number;
      /** @example "669814726c9ca473fff3ee4d" */
      accountId?: string;
      sectors?: {
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @example "Sector 1" */
        name: string;
        /** @example "1234568" */
        cdtecSectorId: string;
        /** @example "100" */
        theoreticalSurface: string;
        /** @example "669814726c9ca473fff3ee4d" */
        farmId?: string;
        /** @example "5618131" */
        wiseconnId?: string;
        /** @example "Loamy" */
        typeOfSoil: string;
        coordinates?: {
          /**
           * @format float
           * @example -25.12
           */
          lng: number;
          /**
           * @format float
           * @example 35.12
           */
          lat: number;
        }[];
        /** @example [] */
        subSectors?: any[];
      }[];
      devices?: {
        id?: string;
        type: string;
        brand: string;
        model: string;
        nodeId?: string | null;
        nodeType?: string | null;
      }[];
      /** @example "58" */
      wiseconnId?: string;
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @default false */
      deleted?: boolean;
      /**
       * @format date-time
       * @default "2023-03-07T19:43:46.303Z"
       */
      createdAt?: string;
      /**
       * @format date-time
       * @default "2023-03-07T19:43:46.303Z"
       */
      updatedAt?: string;
    }[];
  };
}

export interface PutAccountResponse {
  /** @example true */
  success?: boolean;
  /** @example "Cuenta creada exitosamente" */
  message?: string;
}

export interface DeleteAccountResponse {
  /** @example true */
  success?: boolean;
  /** @example "Cuenta eliminada exitosamente" */
  message?: string;
}

export interface GetUserAccountsByUserIdResponse {
  /** @example true */
  success?: boolean;
  /** @example "Cuentas de usuario encontrada exitosamente" */
  message?: string;
  userAccounts?: {
    /** @example "669814726c9ca473fff3ee4d" */
    _id?: string;
    /** @example "Tubesoft" */
    name: string;
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    companies?: {
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @example "Empresa 123" */
      name: string;
      /** @example "12345678-9" */
      rut: string;
      /** @example "Empresa Cerezos" */
      businessName: string;
      /** @example "Chile" */
      country: string;
      /** @example "Region Metropolitana" */
      region: string;
      /** @example "Santiago" */
      commune: string;
      /** @example "123 Vespucio" */
      address: string;
      /** @example false */
      deleted: boolean;
      /**
       * @format date-time
       * @example "2023-03-07T19:43:46.303Z"
       */
      createdAt: string;
      /**
       * @format date-time
       * @example "2023-03-07T19:43:46.303Z"
       */
      updatedAt: string;
    }[];
    farms?: {
      /** @example "Campo Cerezos" */
      name: string;
      /** @example "Calle Ejemplo Exampl 123" */
      location: string;
      /** @example "100" */
      theoreticalSurface: string;
      center: {
        /** @example "-30.35161" */
        lat: string | number;
        /** @example "58.681618" */
        lng: string | number;
      };
      coordinates?: {
        /** @example "-30.35161" */
        lat: string;
        /** @example "58.681618" */
        lng: string;
      }[];
      /**
       * @format float
       * @example 18
       */
      zoom?: number;
      /** @example "669814726c9ca473fff3ee4d" */
      accountId?: string;
      sectors?: {
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @example "Sector 1" */
        name: string;
        /** @example "1234568" */
        cdtecSectorId: string;
        /** @example "100" */
        theoreticalSurface: string;
        /** @example "669814726c9ca473fff3ee4d" */
        farmId?: string;
        /** @example "5618131" */
        wiseconnId?: string;
        /** @example "Loamy" */
        typeOfSoil: string;
        coordinates?: {
          /**
           * @format float
           * @example -25.12
           */
          lng: number;
          /**
           * @format float
           * @example 35.12
           */
          lat: number;
        }[];
        /** @example [] */
        subSectors?: any[];
      }[];
      devices?: {
        id?: string;
        type: string;
        brand: string;
        model: string;
        nodeId?: string | null;
        nodeType?: string | null;
      }[];
      /** @example "58" */
      wiseconnId?: string;
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @default false */
      deleted?: boolean;
      /**
       * @format date-time
       * @default "2023-03-07T19:43:46.303Z"
       */
      createdAt?: string;
      /**
       * @format date-time
       * @default "2023-03-07T19:43:46.303Z"
       */
      updatedAt?: string;
    }[];
  }[];
}

export interface UserSchema {
  /** @example "John" */
  name: string;
  /** @example "Doe" */
  lastname: string;
  /** @example "example@gmail.com" */
  email: string;
  /** @example "+56912345678" */
  phoneNumber?: string | null;
  /** @example "Interno" */
  type?: string | null;
  /** @example "user" */
  role: string;
  /** @example "ACME SA" */
  company?: string | null;
  /** @example "669814726c9ca473fff3ee4d" */
  id?: string;
  /** @example null */
  password?: string | null;
  /** @example null */
  resetPasswordToken?: string | null;
  /** @example false */
  active?: boolean;
  /** @example false */
  deleted?: boolean;
  /**
   * @format date-time
   * @example "2023-03-07T19:43:46.303Z"
   */
  createdAt?: string;
  /**
   * @format date-time
   * @example "2023-03-07T19:43:46.303Z"
   */
  updatedAt?: string;
  /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
  userVerificationToken?: string;
  accounts?: {
    /** @example "669814726c9ca473fff3ee4d" */
    _id?: string;
    /** @example "Tubesoft" */
    name: string;
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    companies?: {
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @example "Empresa 123" */
      name: string;
      /** @example "12345678-9" */
      rut: string;
      /** @example "Empresa Cerezos" */
      businessName: string;
      /** @example "Chile" */
      country: string;
      /** @example "Region Metropolitana" */
      region: string;
      /** @example "Santiago" */
      commune: string;
      /** @example "123 Vespucio" */
      address: string;
      /** @example false */
      deleted: boolean;
      /**
       * @format date-time
       * @example "2023-03-07T19:43:46.303Z"
       */
      createdAt: string;
      /**
       * @format date-time
       * @example "2023-03-07T19:43:46.303Z"
       */
      updatedAt: string;
    }[];
    farms?: {
      /** @example "Campo Cerezos" */
      name: string;
      /** @example "Calle Ejemplo Exampl 123" */
      location: string;
      /** @example "100" */
      theoreticalSurface: string;
      center: {
        /** @example "-30.35161" */
        lat: string | number;
        /** @example "58.681618" */
        lng: string | number;
      };
      coordinates?: {
        /** @example "-30.35161" */
        lat: string;
        /** @example "58.681618" */
        lng: string;
      }[];
      /**
       * @format float
       * @example 18
       */
      zoom?: number;
      /** @example "669814726c9ca473fff3ee4d" */
      accountId?: string;
      sectors?: {
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @example "Sector 1" */
        name: string;
        /** @example "1234568" */
        cdtecSectorId: string;
        /** @example "100" */
        theoreticalSurface: string;
        /** @example "669814726c9ca473fff3ee4d" */
        farmId?: string;
        /** @example "5618131" */
        wiseconnId?: string;
        /** @example "Loamy" */
        typeOfSoil: string;
        coordinates?: {
          /**
           * @format float
           * @example -25.12
           */
          lng: number;
          /**
           * @format float
           * @example 35.12
           */
          lat: number;
        }[];
        /** @example [] */
        subSectors?: any[];
      }[];
      devices?: {
        id?: string;
        type: string;
        brand: string;
        model: string;
        nodeId?: string | null;
        nodeType?: string | null;
      }[];
      /** @example "58" */
      wiseconnId?: string;
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @default false */
      deleted?: boolean;
      /**
       * @format date-time
       * @default "2023-03-07T19:43:46.303Z"
       */
      createdAt?: string;
      /**
       * @format date-time
       * @default "2023-03-07T19:43:46.303Z"
       */
      updatedAt?: string;
    }[];
  }[];
  farms?: {
    /** @example "Campo Cerezos" */
    name: string;
    /** @example "Calle Ejemplo Exampl 123" */
    location: string;
    /** @example "100" */
    theoreticalSurface: string;
    center: {
      /** @example "-30.35161" */
      lat: string | number;
      /** @example "58.681618" */
      lng: string | number;
    };
    coordinates?: {
      /** @example "-30.35161" */
      lat: string;
      /** @example "58.681618" */
      lng: string;
    }[];
    /**
     * @format float
     * @example 18
     */
    zoom?: number;
    /** @example "669814726c9ca473fff3ee4d" */
    accountId?: string;
    sectors?: {
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @example "Sector 1" */
      name: string;
      /** @example "1234568" */
      cdtecSectorId: string;
      /** @example "100" */
      theoreticalSurface: string;
      /** @example "669814726c9ca473fff3ee4d" */
      farmId?: string;
      /** @example "5618131" */
      wiseconnId?: string;
      /** @example "Loamy" */
      typeOfSoil: string;
      coordinates?: {
        /**
         * @format float
         * @example -25.12
         */
        lng: number;
        /**
         * @format float
         * @example 35.12
         */
        lat: number;
      }[];
      /** @example [] */
      subSectors?: any[];
    }[];
    devices?: {
      id?: string;
      type: string;
      brand: string;
      model: string;
      nodeId?: string | null;
      nodeType?: string | null;
    }[];
    /** @example "58" */
    wiseconnId?: string;
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @default false */
    deleted?: boolean;
    /**
     * @format date-time
     * @default "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @default "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
  }[];
}

export interface GetUsersPerPageSchema {
  /**
   * @format float
   * @example 10
   */
  limit?: number;
  /**
   * @format float
   * @example 0
   */
  page?: number;
  /** @example "" */
  searchValue?: string;
  /** @pattern (\|DESC\b|\|ASC\b) */
  sort?: string;
  /** @example "" */
  filterAccount?: string;
  /** @example "" */
  filterFarm?: string;
}

export interface UpdateUserSchema {
  /** @example "669814726c9ca473fff3ee4d" */
  id?: string;
  /** @example "María" */
  name?: string;
  /** @example "Rodríguez" */
  lastname?: string;
  /** @example true */
  active?: boolean;
  /** @example "user" */
  role?: string;
}

export interface PostUserSchema {
  /** @example "John" */
  name: string;
  /** @example "Doe" */
  lastname: string;
  /** @example "example@gmail.com" */
  email: string;
  /** @example "+56912345678" */
  phoneNumber?: string | null;
  /** @example "Interno" */
  type?: string | null;
  /** @example "user" */
  role: string;
  /** @example "ACME SA" */
  company?: string | null;
  accounts?: {
    accountId: string;
    farms?: string[];
  }[];
}

export interface UpdateUserResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuario editado exitosamente" */
  message?: string;
  editedUser?: {
    /** @example "John" */
    name: string;
    /** @example "Doe" */
    lastname: string;
    /** @example "example@gmail.com" */
    email: string;
    /** @example "+56912345678" */
    phoneNumber?: string | null;
    /** @example "Interno" */
    type?: string | null;
    /** @example "user" */
    role: string;
    /** @example "ACME SA" */
    company?: string | null;
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example null */
    password?: string | null;
    /** @example null */
    resetPasswordToken?: string | null;
    /** @example false */
    active?: boolean;
    /** @example false */
    deleted?: boolean;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
    /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
    userVerificationToken?: string;
    accounts?: {
      /** @example "669814726c9ca473fff3ee4d" */
      _id?: string;
      /** @example "Tubesoft" */
      name: string;
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      companies?: {
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @example "Empresa 123" */
        name: string;
        /** @example "12345678-9" */
        rut: string;
        /** @example "Empresa Cerezos" */
        businessName: string;
        /** @example "Chile" */
        country: string;
        /** @example "Region Metropolitana" */
        region: string;
        /** @example "Santiago" */
        commune: string;
        /** @example "123 Vespucio" */
        address: string;
        /** @example false */
        deleted: boolean;
        /**
         * @format date-time
         * @example "2023-03-07T19:43:46.303Z"
         */
        createdAt: string;
        /**
         * @format date-time
         * @example "2023-03-07T19:43:46.303Z"
         */
        updatedAt: string;
      }[];
      farms?: {
        /** @example "Campo Cerezos" */
        name: string;
        /** @example "Calle Ejemplo Exampl 123" */
        location: string;
        /** @example "100" */
        theoreticalSurface: string;
        center: {
          /** @example "-30.35161" */
          lat: string | number;
          /** @example "58.681618" */
          lng: string | number;
        };
        coordinates?: {
          /** @example "-30.35161" */
          lat: string;
          /** @example "58.681618" */
          lng: string;
        }[];
        /**
         * @format float
         * @example 18
         */
        zoom?: number;
        /** @example "669814726c9ca473fff3ee4d" */
        accountId?: string;
        sectors?: {
          /** @example "669814726c9ca473fff3ee4d" */
          id?: string;
          /** @example "Sector 1" */
          name: string;
          /** @example "1234568" */
          cdtecSectorId: string;
          /** @example "100" */
          theoreticalSurface: string;
          /** @example "669814726c9ca473fff3ee4d" */
          farmId?: string;
          /** @example "5618131" */
          wiseconnId?: string;
          /** @example "Loamy" */
          typeOfSoil: string;
          coordinates?: {
            /**
             * @format float
             * @example -25.12
             */
            lng: number;
            /**
             * @format float
             * @example 35.12
             */
            lat: number;
          }[];
          /** @example [] */
          subSectors?: any[];
        }[];
        devices?: {
          id?: string;
          type: string;
          brand: string;
          model: string;
          nodeId?: string | null;
          nodeType?: string | null;
        }[];
        /** @example "58" */
        wiseconnId?: string;
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @default false */
        deleted?: boolean;
        /**
         * @format date-time
         * @default "2023-03-07T19:43:46.303Z"
         */
        createdAt?: string;
        /**
         * @format date-time
         * @default "2023-03-07T19:43:46.303Z"
         */
        updatedAt?: string;
      }[];
    }[];
    farms?: {
      /** @example "Campo Cerezos" */
      name: string;
      /** @example "Calle Ejemplo Exampl 123" */
      location: string;
      /** @example "100" */
      theoreticalSurface: string;
      center: {
        /** @example "-30.35161" */
        lat: string | number;
        /** @example "58.681618" */
        lng: string | number;
      };
      coordinates?: {
        /** @example "-30.35161" */
        lat: string;
        /** @example "58.681618" */
        lng: string;
      }[];
      /**
       * @format float
       * @example 18
       */
      zoom?: number;
      /** @example "669814726c9ca473fff3ee4d" */
      accountId?: string;
      sectors?: {
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @example "Sector 1" */
        name: string;
        /** @example "1234568" */
        cdtecSectorId: string;
        /** @example "100" */
        theoreticalSurface: string;
        /** @example "669814726c9ca473fff3ee4d" */
        farmId?: string;
        /** @example "5618131" */
        wiseconnId?: string;
        /** @example "Loamy" */
        typeOfSoil: string;
        coordinates?: {
          /**
           * @format float
           * @example -25.12
           */
          lng: number;
          /**
           * @format float
           * @example 35.12
           */
          lat: number;
        }[];
        /** @example [] */
        subSectors?: any[];
      }[];
      devices?: {
        id?: string;
        type: string;
        brand: string;
        model: string;
        nodeId?: string | null;
        nodeType?: string | null;
      }[];
      /** @example "58" */
      wiseconnId?: string;
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @default false */
      deleted?: boolean;
      /**
       * @format date-time
       * @default "2023-03-07T19:43:46.303Z"
       */
      createdAt?: string;
      /**
       * @format date-time
       * @default "2023-03-07T19:43:46.303Z"
       */
      updatedAt?: string;
    }[];
  };
}

export interface GetUsersPerPageResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuarios encontrados exitosamente" */
  message?: string;
  users?: {
    /** @example "John" */
    name: string;
    /** @example "Doe" */
    lastname: string;
    /** @example "example@gmail.com" */
    email: string;
    /** @example "+56912345678" */
    phoneNumber?: string | null;
    /** @example "Interno" */
    type?: string | null;
    /** @example "user" */
    role: string;
    /** @example "ACME SA" */
    company?: string | null;
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example null */
    password?: string | null;
    /** @example null */
    resetPasswordToken?: string | null;
    /** @example false */
    active?: boolean;
    /** @example false */
    deleted?: boolean;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
    /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
    userVerificationToken?: string;
    accounts?: {
      /** @example "669814726c9ca473fff3ee4d" */
      _id?: string;
      /** @example "Tubesoft" */
      name: string;
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      companies?: {
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @example "Empresa 123" */
        name: string;
        /** @example "12345678-9" */
        rut: string;
        /** @example "Empresa Cerezos" */
        businessName: string;
        /** @example "Chile" */
        country: string;
        /** @example "Region Metropolitana" */
        region: string;
        /** @example "Santiago" */
        commune: string;
        /** @example "123 Vespucio" */
        address: string;
        /** @example false */
        deleted: boolean;
        /**
         * @format date-time
         * @example "2023-03-07T19:43:46.303Z"
         */
        createdAt: string;
        /**
         * @format date-time
         * @example "2023-03-07T19:43:46.303Z"
         */
        updatedAt: string;
      }[];
      farms?: {
        /** @example "Campo Cerezos" */
        name: string;
        /** @example "Calle Ejemplo Exampl 123" */
        location: string;
        /** @example "100" */
        theoreticalSurface: string;
        center: {
          /** @example "-30.35161" */
          lat: string | number;
          /** @example "58.681618" */
          lng: string | number;
        };
        coordinates?: {
          /** @example "-30.35161" */
          lat: string;
          /** @example "58.681618" */
          lng: string;
        }[];
        /**
         * @format float
         * @example 18
         */
        zoom?: number;
        /** @example "669814726c9ca473fff3ee4d" */
        accountId?: string;
        sectors?: {
          /** @example "669814726c9ca473fff3ee4d" */
          id?: string;
          /** @example "Sector 1" */
          name: string;
          /** @example "1234568" */
          cdtecSectorId: string;
          /** @example "100" */
          theoreticalSurface: string;
          /** @example "669814726c9ca473fff3ee4d" */
          farmId?: string;
          /** @example "5618131" */
          wiseconnId?: string;
          /** @example "Loamy" */
          typeOfSoil: string;
          coordinates?: {
            /**
             * @format float
             * @example -25.12
             */
            lng: number;
            /**
             * @format float
             * @example 35.12
             */
            lat: number;
          }[];
          /** @example [] */
          subSectors?: any[];
        }[];
        devices?: {
          id?: string;
          type: string;
          brand: string;
          model: string;
          nodeId?: string | null;
          nodeType?: string | null;
        }[];
        /** @example "58" */
        wiseconnId?: string;
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @default false */
        deleted?: boolean;
        /**
         * @format date-time
         * @default "2023-03-07T19:43:46.303Z"
         */
        createdAt?: string;
        /**
         * @format date-time
         * @default "2023-03-07T19:43:46.303Z"
         */
        updatedAt?: string;
      }[];
    }[];
    farms?: {
      /** @example "Campo Cerezos" */
      name: string;
      /** @example "Calle Ejemplo Exampl 123" */
      location: string;
      /** @example "100" */
      theoreticalSurface: string;
      center: {
        /** @example "-30.35161" */
        lat: string | number;
        /** @example "58.681618" */
        lng: string | number;
      };
      coordinates?: {
        /** @example "-30.35161" */
        lat: string;
        /** @example "58.681618" */
        lng: string;
      }[];
      /**
       * @format float
       * @example 18
       */
      zoom?: number;
      /** @example "669814726c9ca473fff3ee4d" */
      accountId?: string;
      sectors?: {
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @example "Sector 1" */
        name: string;
        /** @example "1234568" */
        cdtecSectorId: string;
        /** @example "100" */
        theoreticalSurface: string;
        /** @example "669814726c9ca473fff3ee4d" */
        farmId?: string;
        /** @example "5618131" */
        wiseconnId?: string;
        /** @example "Loamy" */
        typeOfSoil: string;
        coordinates?: {
          /**
           * @format float
           * @example -25.12
           */
          lng: number;
          /**
           * @format float
           * @example 35.12
           */
          lat: number;
        }[];
        /** @example [] */
        subSectors?: any[];
      }[];
      devices?: {
        id?: string;
        type: string;
        brand: string;
        model: string;
        nodeId?: string | null;
        nodeType?: string | null;
      }[];
      /** @example "58" */
      wiseconnId?: string;
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @default false */
      deleted?: boolean;
      /**
       * @format date-time
       * @default "2023-03-07T19:43:46.303Z"
       */
      createdAt?: string;
      /**
       * @format date-time
       * @default "2023-03-07T19:43:46.303Z"
       */
      updatedAt?: string;
    }[];
  }[];
  /** @example 1 */
  totalUsers?: number;
}

export interface DeleteUserResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuario/s eliminado/s exitosamente" */
  message?: string;
}

export interface GetUserResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuario encontrado" */
  message?: string;
  user?: {
    /** @example "John" */
    name: string;
    /** @example "Doe" */
    lastname: string;
    /** @example "example@gmail.com" */
    email: string;
    /** @example "+56912345678" */
    phoneNumber?: string | null;
    /** @example "Interno" */
    type?: string | null;
    /** @example "user" */
    role: string;
    /** @example "ACME SA" */
    company?: string | null;
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example null */
    password?: string | null;
    /** @example null */
    resetPasswordToken?: string | null;
    /** @example false */
    active?: boolean;
    /** @example false */
    deleted?: boolean;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
    /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
    userVerificationToken?: string;
    accounts?: {
      /** @example "669814726c9ca473fff3ee4d" */
      _id?: string;
      /** @example "Tubesoft" */
      name: string;
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      companies?: {
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @example "Empresa 123" */
        name: string;
        /** @example "12345678-9" */
        rut: string;
        /** @example "Empresa Cerezos" */
        businessName: string;
        /** @example "Chile" */
        country: string;
        /** @example "Region Metropolitana" */
        region: string;
        /** @example "Santiago" */
        commune: string;
        /** @example "123 Vespucio" */
        address: string;
        /** @example false */
        deleted: boolean;
        /**
         * @format date-time
         * @example "2023-03-07T19:43:46.303Z"
         */
        createdAt: string;
        /**
         * @format date-time
         * @example "2023-03-07T19:43:46.303Z"
         */
        updatedAt: string;
      }[];
      farms?: {
        /** @example "Campo Cerezos" */
        name: string;
        /** @example "Calle Ejemplo Exampl 123" */
        location: string;
        /** @example "100" */
        theoreticalSurface: string;
        center: {
          /** @example "-30.35161" */
          lat: string | number;
          /** @example "58.681618" */
          lng: string | number;
        };
        coordinates?: {
          /** @example "-30.35161" */
          lat: string;
          /** @example "58.681618" */
          lng: string;
        }[];
        /**
         * @format float
         * @example 18
         */
        zoom?: number;
        /** @example "669814726c9ca473fff3ee4d" */
        accountId?: string;
        sectors?: {
          /** @example "669814726c9ca473fff3ee4d" */
          id?: string;
          /** @example "Sector 1" */
          name: string;
          /** @example "1234568" */
          cdtecSectorId: string;
          /** @example "100" */
          theoreticalSurface: string;
          /** @example "669814726c9ca473fff3ee4d" */
          farmId?: string;
          /** @example "5618131" */
          wiseconnId?: string;
          /** @example "Loamy" */
          typeOfSoil: string;
          coordinates?: {
            /**
             * @format float
             * @example -25.12
             */
            lng: number;
            /**
             * @format float
             * @example 35.12
             */
            lat: number;
          }[];
          /** @example [] */
          subSectors?: any[];
        }[];
        devices?: {
          id?: string;
          type: string;
          brand: string;
          model: string;
          nodeId?: string | null;
          nodeType?: string | null;
        }[];
        /** @example "58" */
        wiseconnId?: string;
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @default false */
        deleted?: boolean;
        /**
         * @format date-time
         * @default "2023-03-07T19:43:46.303Z"
         */
        createdAt?: string;
        /**
         * @format date-time
         * @default "2023-03-07T19:43:46.303Z"
         */
        updatedAt?: string;
      }[];
    }[];
    farms?: {
      /** @example "Campo Cerezos" */
      name: string;
      /** @example "Calle Ejemplo Exampl 123" */
      location: string;
      /** @example "100" */
      theoreticalSurface: string;
      center: {
        /** @example "-30.35161" */
        lat: string | number;
        /** @example "58.681618" */
        lng: string | number;
      };
      coordinates?: {
        /** @example "-30.35161" */
        lat: string;
        /** @example "58.681618" */
        lng: string;
      }[];
      /**
       * @format float
       * @example 18
       */
      zoom?: number;
      /** @example "669814726c9ca473fff3ee4d" */
      accountId?: string;
      sectors?: {
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @example "Sector 1" */
        name: string;
        /** @example "1234568" */
        cdtecSectorId: string;
        /** @example "100" */
        theoreticalSurface: string;
        /** @example "669814726c9ca473fff3ee4d" */
        farmId?: string;
        /** @example "5618131" */
        wiseconnId?: string;
        /** @example "Loamy" */
        typeOfSoil: string;
        coordinates?: {
          /**
           * @format float
           * @example -25.12
           */
          lng: number;
          /**
           * @format float
           * @example 35.12
           */
          lat: number;
        }[];
        /** @example [] */
        subSectors?: any[];
      }[];
      devices?: {
        id?: string;
        type: string;
        brand: string;
        model: string;
        nodeId?: string | null;
        nodeType?: string | null;
      }[];
      /** @example "58" */
      wiseconnId?: string;
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @default false */
      deleted?: boolean;
      /**
       * @format date-time
       * @default "2023-03-07T19:43:46.303Z"
       */
      createdAt?: string;
      /**
       * @format date-time
       * @default "2023-03-07T19:43:46.303Z"
       */
      updatedAt?: string;
    }[];
  };
}

export interface PostUserResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuarios creado exitosamente" */
  message?: string;
}

export interface RegisterRequest {
  /**
   * @minLength 3
   * @maxLength 255
   * @pattern ^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/
   * @example "example@gmail.com"
   */
  email: string;
  /**
   * @minLength 2
   * @maxLength 255
   * @pattern ^[a-zA-Z0-9]
   * @example "María"
   */
  name: string;
  /**
   * @minLength 2
   * @maxLength 255
   * @pattern ^[a-zA-Z0-9]
   * @example "Rodríguez"
   */
  lastname: string;
  /**
   * @pattern ^[a-zA-Z0-9]
   * @example null
   */
  password?: string | null;
  /** @example "user" */
  role?: string;
}

export interface PutDeletedUserRequest {
  /**
   * @minLength 2
   * @maxLength 255
   * @pattern ^[a-zA-Z0-9]
   * @example "María"
   */
  name: string;
  /**
   * @minLength 2
   * @maxLength 255
   * @pattern ^[a-zA-Z0-9]
   * @example "Rodríguez"
   */
  lastname: string;
  /** @example "user" */
  role?: string;
}

export interface LoginRequest {
  /**
   * @minLength 3
   * @maxLength 255
   * @pattern ^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/
   * @example "example@gmail.com"
   */
  email: string;
  /**
   * @pattern ^[a-zA-Z0-9]
   * @example "password123"
   */
  password: string;
}

export interface VerificationRequest {
  /**
   * @minLength 8
   * @pattern ^[a-zA-Z0-9]
   * @example "password123"
   */
  password: string;
  /** @example "password123" */
  confirmPassword: any;
  /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
  token: string;
}

export interface ResetPasswordRequest {
  /**
   * @minLength 8
   * @pattern ^[a-zA-Z0-9]
   * @example "password123"
   */
  password: string;
  /** @example "password123" */
  confirmPassword: any;
  /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
  token: string;
}

export interface RecoverPasswordRequest {
  /**
   * Email de usuario
   * @format email
   * @minLength 3
   * @maxLength 255
   */
  email: string;
}

export interface RegisterResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuario creado con éxito" */
  message?: string;
  user?: {
    /** @example "John" */
    name: string;
    /** @example "Doe" */
    lastname: string;
    /** @example "example@gmail.com" */
    email: string;
    /** @example "+56912345678" */
    phoneNumber?: string | null;
    /** @example "Interno" */
    type?: string | null;
    /** @example "user" */
    role: string;
    /** @example "ACME SA" */
    company?: string | null;
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example null */
    password?: string | null;
    /** @example null */
    resetPasswordToken?: string | null;
    /** @example false */
    active?: boolean;
    /** @example false */
    deleted?: boolean;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
    /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
    userVerificationToken?: string;
    accounts?: {
      /** @example "669814726c9ca473fff3ee4d" */
      _id?: string;
      /** @example "Tubesoft" */
      name: string;
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      companies?: {
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @example "Empresa 123" */
        name: string;
        /** @example "12345678-9" */
        rut: string;
        /** @example "Empresa Cerezos" */
        businessName: string;
        /** @example "Chile" */
        country: string;
        /** @example "Region Metropolitana" */
        region: string;
        /** @example "Santiago" */
        commune: string;
        /** @example "123 Vespucio" */
        address: string;
        /** @example false */
        deleted: boolean;
        /**
         * @format date-time
         * @example "2023-03-07T19:43:46.303Z"
         */
        createdAt: string;
        /**
         * @format date-time
         * @example "2023-03-07T19:43:46.303Z"
         */
        updatedAt: string;
      }[];
      farms?: {
        /** @example "Campo Cerezos" */
        name: string;
        /** @example "Calle Ejemplo Exampl 123" */
        location: string;
        /** @example "100" */
        theoreticalSurface: string;
        center: {
          /** @example "-30.35161" */
          lat: string | number;
          /** @example "58.681618" */
          lng: string | number;
        };
        coordinates?: {
          /** @example "-30.35161" */
          lat: string;
          /** @example "58.681618" */
          lng: string;
        }[];
        /**
         * @format float
         * @example 18
         */
        zoom?: number;
        /** @example "669814726c9ca473fff3ee4d" */
        accountId?: string;
        sectors?: {
          /** @example "669814726c9ca473fff3ee4d" */
          id?: string;
          /** @example "Sector 1" */
          name: string;
          /** @example "1234568" */
          cdtecSectorId: string;
          /** @example "100" */
          theoreticalSurface: string;
          /** @example "669814726c9ca473fff3ee4d" */
          farmId?: string;
          /** @example "5618131" */
          wiseconnId?: string;
          /** @example "Loamy" */
          typeOfSoil: string;
          coordinates?: {
            /**
             * @format float
             * @example -25.12
             */
            lng: number;
            /**
             * @format float
             * @example 35.12
             */
            lat: number;
          }[];
          /** @example [] */
          subSectors?: any[];
        }[];
        devices?: {
          id?: string;
          type: string;
          brand: string;
          model: string;
          nodeId?: string | null;
          nodeType?: string | null;
        }[];
        /** @example "58" */
        wiseconnId?: string;
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @default false */
        deleted?: boolean;
        /**
         * @format date-time
         * @default "2023-03-07T19:43:46.303Z"
         */
        createdAt?: string;
        /**
         * @format date-time
         * @default "2023-03-07T19:43:46.303Z"
         */
        updatedAt?: string;
      }[];
    }[];
    farms?: {
      /** @example "Campo Cerezos" */
      name: string;
      /** @example "Calle Ejemplo Exampl 123" */
      location: string;
      /** @example "100" */
      theoreticalSurface: string;
      center: {
        /** @example "-30.35161" */
        lat: string | number;
        /** @example "58.681618" */
        lng: string | number;
      };
      coordinates?: {
        /** @example "-30.35161" */
        lat: string;
        /** @example "58.681618" */
        lng: string;
      }[];
      /**
       * @format float
       * @example 18
       */
      zoom?: number;
      /** @example "669814726c9ca473fff3ee4d" */
      accountId?: string;
      sectors?: {
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @example "Sector 1" */
        name: string;
        /** @example "1234568" */
        cdtecSectorId: string;
        /** @example "100" */
        theoreticalSurface: string;
        /** @example "669814726c9ca473fff3ee4d" */
        farmId?: string;
        /** @example "5618131" */
        wiseconnId?: string;
        /** @example "Loamy" */
        typeOfSoil: string;
        coordinates?: {
          /**
           * @format float
           * @example -25.12
           */
          lng: number;
          /**
           * @format float
           * @example 35.12
           */
          lat: number;
        }[];
        /** @example [] */
        subSectors?: any[];
      }[];
      devices?: {
        id?: string;
        type: string;
        brand: string;
        model: string;
        nodeId?: string | null;
        nodeType?: string | null;
      }[];
      /** @example "58" */
      wiseconnId?: string;
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @default false */
      deleted?: boolean;
      /**
       * @format date-time
       * @default "2023-03-07T19:43:46.303Z"
       */
      createdAt?: string;
      /**
       * @format date-time
       * @default "2023-03-07T19:43:46.303Z"
       */
      updatedAt?: string;
    }[];
  };
}

export interface LoginResponse {
  /** @example true */
  success?: boolean;
  /** @example "Login exitoso" */
  message?: string;
  user?: {
    /** @example "John" */
    name: string;
    /** @example "Doe" */
    lastname: string;
    /** @example "example@gmail.com" */
    email: string;
    /** @example "+56912345678" */
    phoneNumber?: string | null;
    /** @example "Interno" */
    type?: string | null;
    /** @example "user" */
    role: string;
    /** @example "ACME SA" */
    company?: string | null;
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example null */
    password?: string | null;
    /** @example null */
    resetPasswordToken?: string | null;
    /** @example false */
    active?: boolean;
    /** @example false */
    deleted?: boolean;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
    /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
    userVerificationToken?: string;
    accounts?: {
      /** @example "669814726c9ca473fff3ee4d" */
      _id?: string;
      /** @example "Tubesoft" */
      name: string;
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      companies?: {
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @example "Empresa 123" */
        name: string;
        /** @example "12345678-9" */
        rut: string;
        /** @example "Empresa Cerezos" */
        businessName: string;
        /** @example "Chile" */
        country: string;
        /** @example "Region Metropolitana" */
        region: string;
        /** @example "Santiago" */
        commune: string;
        /** @example "123 Vespucio" */
        address: string;
        /** @example false */
        deleted: boolean;
        /**
         * @format date-time
         * @example "2023-03-07T19:43:46.303Z"
         */
        createdAt: string;
        /**
         * @format date-time
         * @example "2023-03-07T19:43:46.303Z"
         */
        updatedAt: string;
      }[];
      farms?: {
        /** @example "Campo Cerezos" */
        name: string;
        /** @example "Calle Ejemplo Exampl 123" */
        location: string;
        /** @example "100" */
        theoreticalSurface: string;
        center: {
          /** @example "-30.35161" */
          lat: string | number;
          /** @example "58.681618" */
          lng: string | number;
        };
        coordinates?: {
          /** @example "-30.35161" */
          lat: string;
          /** @example "58.681618" */
          lng: string;
        }[];
        /**
         * @format float
         * @example 18
         */
        zoom?: number;
        /** @example "669814726c9ca473fff3ee4d" */
        accountId?: string;
        sectors?: {
          /** @example "669814726c9ca473fff3ee4d" */
          id?: string;
          /** @example "Sector 1" */
          name: string;
          /** @example "1234568" */
          cdtecSectorId: string;
          /** @example "100" */
          theoreticalSurface: string;
          /** @example "669814726c9ca473fff3ee4d" */
          farmId?: string;
          /** @example "5618131" */
          wiseconnId?: string;
          /** @example "Loamy" */
          typeOfSoil: string;
          coordinates?: {
            /**
             * @format float
             * @example -25.12
             */
            lng: number;
            /**
             * @format float
             * @example 35.12
             */
            lat: number;
          }[];
          /** @example [] */
          subSectors?: any[];
        }[];
        devices?: {
          id?: string;
          type: string;
          brand: string;
          model: string;
          nodeId?: string | null;
          nodeType?: string | null;
        }[];
        /** @example "58" */
        wiseconnId?: string;
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @default false */
        deleted?: boolean;
        /**
         * @format date-time
         * @default "2023-03-07T19:43:46.303Z"
         */
        createdAt?: string;
        /**
         * @format date-time
         * @default "2023-03-07T19:43:46.303Z"
         */
        updatedAt?: string;
      }[];
    }[];
    farms?: {
      /** @example "Campo Cerezos" */
      name: string;
      /** @example "Calle Ejemplo Exampl 123" */
      location: string;
      /** @example "100" */
      theoreticalSurface: string;
      center: {
        /** @example "-30.35161" */
        lat: string | number;
        /** @example "58.681618" */
        lng: string | number;
      };
      coordinates?: {
        /** @example "-30.35161" */
        lat: string;
        /** @example "58.681618" */
        lng: string;
      }[];
      /**
       * @format float
       * @example 18
       */
      zoom?: number;
      /** @example "669814726c9ca473fff3ee4d" */
      accountId?: string;
      sectors?: {
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @example "Sector 1" */
        name: string;
        /** @example "1234568" */
        cdtecSectorId: string;
        /** @example "100" */
        theoreticalSurface: string;
        /** @example "669814726c9ca473fff3ee4d" */
        farmId?: string;
        /** @example "5618131" */
        wiseconnId?: string;
        /** @example "Loamy" */
        typeOfSoil: string;
        coordinates?: {
          /**
           * @format float
           * @example -25.12
           */
          lng: number;
          /**
           * @format float
           * @example 35.12
           */
          lat: number;
        }[];
        /** @example [] */
        subSectors?: any[];
      }[];
      devices?: {
        id?: string;
        type: string;
        brand: string;
        model: string;
        nodeId?: string | null;
        nodeType?: string | null;
      }[];
      /** @example "58" */
      wiseconnId?: string;
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @default false */
      deleted?: boolean;
      /**
       * @format date-time
       * @default "2023-03-07T19:43:46.303Z"
       */
      createdAt?: string;
      /**
       * @format date-time
       * @default "2023-03-07T19:43:46.303Z"
       */
      updatedAt?: string;
    }[];
  };
  /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
  token?: string;
}

export interface ErrorResponse {
  /** @example true */
  success?: boolean;
  /** @example "Login fallido" */
  message?: string;
  /** @example "Error en el servidor" */
  errorMessage?: string;
}

export interface VerificationResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuario verificado correctamente" */
  message?: string;
}

export interface ResetPasswordResponse {
  /** @example true */
  success?: boolean;
  /** @example "Contraseña actualizada" */
  message?: string;
}

export interface RecoverPasswordResponse {
  /** @example true */
  success?: boolean;
  /** @example "Correo de recuperar contraseña enviado" */
  message?: string;
}

export interface VarietySchema {
  /** @example "669814726c9ca473fff3ee4d" */
  id?: string;
  /** @example "Maiz dulce" */
  name: string;
  /** @example "Maiz" */
  species: string;
  phenologicalStates?: {
    name: string;
    /** @format date-time */
    iniDate: string;
    /** @format date-time */
    endDate: string;
  }[];
}

export interface PostVarietySchema {
  /** @example "Maiz dulce" */
  name: string;
  /** @example "Maiz" */
  species: string;
  phenologicalStates?: {
    name: string;
    /** @format date-time */
    iniDate: string;
    /** @format date-time */
    endDate: string;
  }[];
}

export interface PutVarietySchema {
  /** @example "Maiz dulce" */
  name: string;
  /** @example "Maiz" */
  species: string;
  phenologicalStates?: {
    name: string;
    /** @format date-time */
    iniDate: string;
    /** @format date-time */
    endDate: string;
  }[];
}

export interface DeleteVarietySchema {
  /** @example "669814726c9ca473fff3ee4d" */
  id: string;
}

export interface GetVarietiesPerPageResponse {
  /** @example true */
  success?: boolean;
  /** @example "Variedades encontradas exitosamente" */
  message?: string;
  varieties?: {
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "Maiz dulce" */
    name: string;
    /** @example "Maiz" */
    species: string;
    phenologicalStates?: {
      name: string;
      /** @format date-time */
      iniDate: string;
      /** @format date-time */
      endDate: string;
    }[];
  }[];
  /** @example 1 */
  totalVarieties?: number;
}

export interface PostVarietyResponse {
  /** @example true */
  success?: boolean;
  /** @example "Variedad creada exitosamente" */
  message?: string;
}

export interface PutVarietyResponse {
  /** @example true */
  success?: boolean;
  /** @example "Variedad editada exitosamente" */
  message?: string;
}

export interface GetVarietyByIdResponse {
  /** @example true */
  success?: boolean;
  /** @example "Variedad encontrada exitosamente" */
  message?: string;
  variety?: {
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "Maiz dulce" */
    name: string;
    /** @example "Maiz" */
    species: string;
    phenologicalStates?: {
      name: string;
      /** @format date-time */
      iniDate: string;
      /** @format date-time */
      endDate: string;
    }[];
  };
}

export interface DeleteVarietyResponse {
  /** @example true */
  success?: boolean;
  /** @example "Variedad eliminada exitosamente" */
  message?: string;
}

export interface GetSpeciesResponse {
  /** @example true */
  success?: boolean;
  /** @example "Especies encontradas exitosamente" */
  message?: string;
  species?: {
    id?: string;
    name?: string;
  }[];
}

export interface PhenologicalState {
  /** @example "Germinación" */
  name: string;
  /**
   * @format date-time
   * @example "2024-03-01"
   */
  startDate: string;
  /**
   * @format date-time
   * @example "2024-03-01"
   */
  endDate: string;
}

export interface DeletePhenologicalState {
  /** @example "66cbf41852c77781afe27220" */
  id: string;
  /** @example "Germinación" */
  name: string;
}

export interface PostCultivationCycleSchema {
  /** @example "Primer cultivo" */
  name: string;
  /** @example [{"name":"Germinación","startDate":"2024-03-01","endDate":"2024-03-15"},{"name":"Floración","startDate":"2024-04-01","endDate":"2024-04-20"}] */
  phenologicalStates: {
    /** @example "Germinación" */
    name: string;
    /**
     * @format date-time
     * @example "2024-03-01"
     */
    startDate: string;
    /**
     * @format date-time
     * @example "2024-03-01"
     */
    endDate: string;
  }[];
  /** @example "66cbf41852c77781afe27220" */
  variety: string;
  /** @example "66cbf41852c77781afe27220" */
  sector: string;
  /**
   * @format date-time
   * @example "2024-03-01"
   */
  startDate: string;
  /**
   * @format date-time
   * @example "2024-04-01"
   */
  endDate: string;
}

export interface CultivationCyclePopulatedSchema {
  /** @example "66cbf41852c77781afe27220" */
  id: string;
  /** @example "Primer cultivo" */
  name: string;
  /** @example [{"name":"Germinación","startDate":"2024-03-01","endDate":"2024-03-15"},{"name":"Floración","startDate":"2024-04-01","endDate":"2024-04-20"}] */
  phenologicalStates: {
    /** @example "Germinación" */
    name: string;
    /**
     * @format date-time
     * @example "2024-03-01"
     */
    startDate: string;
    /**
     * @format date-time
     * @example "2024-03-01"
     */
    endDate: string;
  }[];
  variety: {
    /** @example "Howard" */
    name: string;
    /** @example "Nogal" */
    species: string;
  };
  /**
   * @format date-time
   * @example "2024-03-01"
   */
  startDate: string;
  /**
   * @format date-time
   * @example "2024-04-01"
   */
  endDate: string;
}

export interface PutPhenologicalStateSchema {
  /** @example "Germinación" */
  name: string;
  /**
   * @format date-time
   * @example "2024-03-01"
   */
  startDate: string;
  /**
   * @format date-time
   * @example "2024-03-01"
   */
  endDate: string;
}

export interface UpdateCultivationCycleSchema {
  /** @example "66cbf41852c77781afe27220" */
  id: string;
  /** @example "Primer cultivo" */
  name?: string;
  /**
   * @format date-time
   * @example "2024-03-01"
   */
  startDate?: string;
  /**
   * @format date-time
   * @example "2024-04-01"
   */
  endDate?: string;
  /** @example "66cbf41852c77781afe27220" */
  variety?: string;
}

export interface DeletePhenologicalStateSchema {
  /** @example "66cbf41852c77781afe27220" */
  id: string;
  /** @example "Germinación" */
  name: string;
}

export interface PostCultivationCycleResponse {
  /** @example true */
  success?: boolean;
  /** @example "Ciclo de cultivo creado exitosamente" */
  message?: string;
  cultivationCycle?: {
    /** @example "66cbf41852c77781afe27220" */
    id: string;
    /** @example "Primer cultivo" */
    name: string;
    /** @example [{"name":"Germinación","startDate":"2024-03-01","endDate":"2024-03-15"},{"name":"Floración","startDate":"2024-04-01","endDate":"2024-04-20"}] */
    phenologicalStates: {
      /** @example "Germinación" */
      name: string;
      /**
       * @format date-time
       * @example "2024-03-01"
       */
      startDate: string;
      /**
       * @format date-time
       * @example "2024-03-01"
       */
      endDate: string;
    }[];
    /** @example "66cbf41852c77781afe27220" */
    variety: string;
    /** @example "66cbf41852c77781afe27220" */
    sector: string;
    /**
     * @format date-time
     * @example "2024-03-01"
     */
    startDate: string;
    /**
     * @format date-time
     * @example "2024-04-01"
     */
    endDate: string;
  };
}

export interface GetCultivationCyclesBySectorResponse {
  /** @example true */
  success?: boolean;
  /** @example "Obtenidos ciclos de cultivo" */
  message?: string;
  cultivationCycles: {
    /** @example "66cbf41852c77781afe27220" */
    id: string;
    /** @example "Primer cultivo" */
    name: string;
    /** @example [{"name":"Germinación","startDate":"2024-03-01","endDate":"2024-03-15"},{"name":"Floración","startDate":"2024-04-01","endDate":"2024-04-20"}] */
    phenologicalStates: {
      /** @example "Germinación" */
      name: string;
      /**
       * @format date-time
       * @example "2024-03-01"
       */
      startDate: string;
      /**
       * @format date-time
       * @example "2024-03-01"
       */
      endDate: string;
    }[];
    variety: {
      /** @example "Howard" */
      name: string;
      /** @example "Nogal" */
      species: string;
    };
    /**
     * @format date-time
     * @example "2024-03-01"
     */
    startDate: string;
    /**
     * @format date-time
     * @example "2024-04-01"
     */
    endDate: string;
  }[];
}

export interface DeleteCultivationCycleResponse {
  /** @example true */
  success?: boolean;
  /** @example "Ciclo de cultivo eliminado" */
  message?: string;
}

export interface PutPhenologicalStateResponse {
  /** @example true */
  success?: boolean;
  /** @example "Agregado estado fenológico" */
  message?: string;
  updatedCycle: {
    /** @example "60f7d2b59f1b1c001c9c4b1d" */
    id: string;
    /** @example "Primer Ciclo" */
    name: string;
    phenologicalStates: {
      /** @example "Germinación" */
      name: string;
      /**
       * @format date-time
       * @example "2024-03-01"
       */
      startDate: string;
      /**
       * @format date-time
       * @example "2024-03-01"
       */
      endDate: string;
    }[];
  };
}

export interface UpdateCultivationCycleResponse {
  /** @example true */
  success?: boolean;
  /** @example "Agregado estado fenológico" */
  message?: string;
  updatedCycle?: {
    /** @example "66cbf41852c77781afe27220" */
    id: string;
    /** @example "Primer cultivo" */
    name: string;
    /** @example [{"name":"Germinación","startDate":"2024-03-01","endDate":"2024-03-15"},{"name":"Floración","startDate":"2024-04-01","endDate":"2024-04-20"}] */
    phenologicalStates: {
      /** @example "Germinación" */
      name: string;
      /**
       * @format date-time
       * @example "2024-03-01"
       */
      startDate: string;
      /**
       * @format date-time
       * @example "2024-03-01"
       */
      endDate: string;
    }[];
    /** @example "66cbf41852c77781afe27220" */
    variety: string;
    /** @example "66cbf41852c77781afe27220" */
    sector: string;
    /**
     * @format date-time
     * @example "2024-03-01"
     */
    startDate: string;
    /**
     * @format date-time
     * @example "2024-04-01"
     */
    endDate: string;
  };
}

export interface DeletePhenologicalStateResponse {
  /** @example true */
  success?: boolean;
  /** @example "Estado fenológico eliminado" */
  message?: string;
}

export interface UploadImageToStorageSchema {
  /** @example "1" */
  deviceId: string;
}

export interface GetGoogleStorageImagesResponse {
  /** @example true */
  success?: boolean;
  /** @example "Equipo encontrado exitosamente" */
  message?: string;
  images: {
    /** @example "https://storage.googleapis.com/devices-images-storage/1/image.jpg" */
    url?: string;
    /** @example "image.jpg" */
    fileName?: string;
    /** @example "1" */
    deviceId?: string;
  }[];
}

export interface UploadImageToStorageResponse {
  /** @example true */
  success?: boolean;
  /** @example "Imagen subida exitosamente" */
  message?: string;
  /** @example "https://storage.googleapis.com/devices-images-storage/1/image.jpg" */
  url?: string;
}

export interface DeleteImageFromStorageResponse {
  /** @example true */
  success?: boolean;
  /** @example "Imagen eliminada exitosamente" */
  message?: string;
}

export interface WorkOrderSchema {
  /** @example "669814726c9ca473fff3ee4d" */
  id?: string;
  /** @example "123456" */
  name?: string;
  /**
   * @format date-time
   * @example "2024-08-08T12:00:00.000Z"
   */
  softlandCreationDate?: string;
  /** @example "p" */
  status?: string;
  dispatchGuides?: {
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "123456" */
    name?: string;
    /**
     * @format date-time
     * @example "2024-08-08T12:00:00.000Z"
     */
    dGuideSoftLandCreationDate?: string;
    /** @example "s" */
    type?: string;
    devices?: {
      /** @example "669814726c9ca473fff3ee4d" */
      _id: string;
      deviceModel?: {
        /** @example "669814726c9ca473fff3ee4d" */
        _id: string;
        /** @example "669814726c9ca473fff3ee4d" */
        id: string;
        name: string;
        model?: string;
        group?: string;
        subGroup?: string;
        codProd?: string;
        /** @default false */
        deleted: boolean;
        /** @example "135654" */
        cdtecDeviceId?: string;
      };
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @example "node" */
      deviceType: string;
      /** @example "1351-545-548-DP2564" */
      uniqueSoftlandId: string;
      /** @example "DP50256" */
      serie?: string;
      /** @example "135436" */
      nodeId: string;
      /** @example "suelo" */
      environmentalFactor?: string;
      /** @example true */
      isGatewayNode?: boolean;
      nodeChip?: string;
      /** @example "123456" */
      deviceId: string;
      /** @example "2024-05-01T00:00:00.000Z" */
      createdAt: string;
      /** @example "2024-05-01T00:00:00.000Z" */
      updatedAt: string;
      /** @example "active" */
      status: string;
      /** @example false */
      deleted: boolean;
      /** @example "Nuevo sensor" */
      comment?: string;
      /** @example "669814726c9ca473fff3ee4d" */
      farm: string;
      /** @example "669814726c9ca473fff3ee4d" */
      sector?: string;
      /** @example "669814726c9ca473fff3ee4d" */
      subSector?: string;
      coordinates?: {
        /**
         * @format float
         * @example -25.12
         */
        lng: number;
        /**
         * @format float
         * @example 35.12
         */
        lat: number;
      };
    }[];
  }[];
}

export interface GetDispatchGuidesByAccountIdSchema {
  /** @example "669814726c9ca473fff3ee4d" */
  accountId?: string;
}

export interface PutWorkOrdersFromSoftlandSchema {
  /** @example "669814726c9ca473fff3ee4d" */
  accountId?: string;
}

export interface GetWorkOrdersPerPageResponse {
  /** @example true */
  success?: boolean;
  /** @example "Ordenes de trabajo encontradas exitosamente" */
  message?: string;
  workOrders?: {
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "123456" */
    name?: string;
    /**
     * @format date-time
     * @example "2024-08-08T12:00:00.000Z"
     */
    softlandCreationDate?: string;
    /** @example "p" */
    status?: string;
    dispatchGuides?: {
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @example "123456" */
      name?: string;
      /**
       * @format date-time
       * @example "2024-08-08T12:00:00.000Z"
       */
      dGuideSoftLandCreationDate?: string;
      /** @example "s" */
      type?: string;
      devices?: {
        /** @example "669814726c9ca473fff3ee4d" */
        _id: string;
        deviceModel?: {
          /** @example "669814726c9ca473fff3ee4d" */
          _id: string;
          /** @example "669814726c9ca473fff3ee4d" */
          id: string;
          name: string;
          model?: string;
          group?: string;
          subGroup?: string;
          codProd?: string;
          /** @default false */
          deleted: boolean;
          /** @example "135654" */
          cdtecDeviceId?: string;
        };
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @example "node" */
        deviceType: string;
        /** @example "1351-545-548-DP2564" */
        uniqueSoftlandId: string;
        /** @example "DP50256" */
        serie?: string;
        /** @example "135436" */
        nodeId: string;
        /** @example "suelo" */
        environmentalFactor?: string;
        /** @example true */
        isGatewayNode?: boolean;
        nodeChip?: string;
        /** @example "123456" */
        deviceId: string;
        /** @example "2024-05-01T00:00:00.000Z" */
        createdAt: string;
        /** @example "2024-05-01T00:00:00.000Z" */
        updatedAt: string;
        /** @example "active" */
        status: string;
        /** @example false */
        deleted: boolean;
        /** @example "Nuevo sensor" */
        comment?: string;
        /** @example "669814726c9ca473fff3ee4d" */
        farm: string;
        /** @example "669814726c9ca473fff3ee4d" */
        sector?: string;
        /** @example "669814726c9ca473fff3ee4d" */
        subSector?: string;
        coordinates?: {
          /**
           * @format float
           * @example -25.12
           */
          lng: number;
          /**
           * @format float
           * @example 35.12
           */
          lat: number;
        };
      }[];
    }[];
  }[];
  /** @example 1 */
  totalWorkOrders?: number;
}

export interface GetDispatchGuidesByAccountIdResponse {
  /** @example true */
  success?: boolean;
  /** @example "Ordenes de encontradas exitosamente" */
  message?: string;
  dispatchGuides?: {
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "123456" */
    name?: string;
    /**
     * @format date-time
     * @example "2024-08-08T12:00:00.000Z"
     */
    dGuideSoftLandCreationDate?: string;
    /** @example "s" */
    type?: string;
    devices?: {
      /** @example "669814726c9ca473fff3ee4d" */
      _id: string;
      deviceModel?: {
        /** @example "669814726c9ca473fff3ee4d" */
        _id: string;
        /** @example "669814726c9ca473fff3ee4d" */
        id: string;
        name: string;
        model?: string;
        group?: string;
        subGroup?: string;
        codProd?: string;
        /** @default false */
        deleted: boolean;
        /** @example "135654" */
        cdtecDeviceId?: string;
      };
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @example "node" */
      deviceType: string;
      /** @example "1351-545-548-DP2564" */
      uniqueSoftlandId: string;
      /** @example "DP50256" */
      serie?: string;
      /** @example "135436" */
      nodeId: string;
      /** @example "suelo" */
      environmentalFactor?: string;
      /** @example true */
      isGatewayNode?: boolean;
      nodeChip?: string;
      /** @example "123456" */
      deviceId: string;
      /** @example "2024-05-01T00:00:00.000Z" */
      createdAt: string;
      /** @example "2024-05-01T00:00:00.000Z" */
      updatedAt: string;
      /** @example "active" */
      status: string;
      /** @example false */
      deleted: boolean;
      /** @example "Nuevo sensor" */
      comment?: string;
      /** @example "669814726c9ca473fff3ee4d" */
      farm: string;
      /** @example "669814726c9ca473fff3ee4d" */
      sector?: string;
      /** @example "669814726c9ca473fff3ee4d" */
      subSector?: string;
      coordinates?: {
        /**
         * @format float
         * @example -25.12
         */
        lng: number;
        /**
         * @format float
         * @example 35.12
         */
        lat: number;
      };
    }[];
  }[];
}

export interface PutWorkOrdersFromSoftlandResponse {
  /** @example true */
  success?: boolean;
  /** @example "Ordenes de trabajo actualizadas exitosamente" */
  message?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Template REST API Docs
 * @version 0.1.24
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  accounts = {
    /**
     * @description Get accounts information
     *
     * @tags Account
     * @name AccountsList
     * @request GET:/accounts
     * @secure
     */
    accountsList: (
      query?: {
        page?: number;
        limit?: number;
        searchValue?: string;
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetAccountsPerPageResponse, void>({
        path: `/accounts`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Creates an account.
     *
     * @tags Account
     * @name AccountsCreate
     * @request POST:/accounts
     * @secure
     */
    accountsCreate: (data: PostAccountSchema, params: RequestParams = {}) =>
      this.request<PostAccountResponse, void>({
        path: `/accounts`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get account information
     *
     * @tags Account
     * @name AccountsDetail
     * @request GET:/accounts/{id}
     * @secure
     */
    accountsDetail: (id?: string, params: RequestParams = {}) =>
      this.request<GetAccountByIdResponse, void>({
        path: `/accounts/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Modifies an account.
     *
     * @tags Account
     * @name AccountsUpdate
     * @request PUT:/accounts/{id}
     * @secure
     */
    accountsUpdate: (id: string, data: PutAccountSchema, params: RequestParams = {}) =>
      this.request<PutAccountResponse, void>({
        path: `/accounts/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Delete an account by ID.
     *
     * @tags Account
     * @name AccountsDelete
     * @request DELETE:/accounts/{id}
     * @secure
     */
    accountsDelete: (id: string, params: RequestParams = {}) =>
      this.request<DeleteAccountResponse, void>({
        path: `/accounts/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  userAccounts = {
    /**
     * @description Get information of all accounts from user
     *
     * @tags User, Account
     * @name UserAccountsDetail
     * @request GET:/user-accounts/{id}
     * @secure
     */
    userAccountsDetail: (id?: string, params: RequestParams = {}) =>
      this.request<GetUserAccountsByUserIdResponse, void>({
        path: `/user-accounts/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  register = {
    /**
     * @description Creates a new user that must afterwards verify the account with a token
     *
     * @tags Auth
     * @name RegisterCreate
     * @request POST:/register
     * @secure
     */
    registerCreate: (data: RegisterRequest, params: RequestParams = {}) =>
      this.request<RegisterResponse, void>({
        path: `/register`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  login = {
    /**
     * @description Authenticates a user by email and password and returns a JWT token
     *
     * @tags Auth
     * @name LoginCreate
     * @request POST:/login
     * @secure
     */
    loginCreate: (data: LoginRequest, params: RequestParams = {}) =>
      this.request<LoginResponse, ErrorResponse | void>({
        path: `/login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  recoverPassword = {
    /**
     * @description Sends an email to the user with instructions to recover password
     *
     * @tags Auth
     * @name RecoverPasswordCreate
     * @request POST:/recover-password
     * @secure
     */
    recoverPasswordCreate: (data: RecoverPasswordRequest, params: RequestParams = {}) =>
      this.request<RecoverPasswordResponse, void>({
        path: `/recover-password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  resetPassword = {
    /**
     * @description Resets a user's password
     *
     * @tags Auth
     * @name ResetPasswordCreate
     * @request POST:/reset-password
     * @secure
     */
    resetPasswordCreate: (data: ResetPasswordRequest, params: RequestParams = {}) =>
      this.request<ResetPasswordResponse, void>({
        path: `/reset-password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  userVerification = {
    /**
     * @description Verifies user account with a token
     *
     * @tags Auth
     * @name UserVerificationCreate
     * @request POST:/user-verification
     * @secure
     */
    userVerificationCreate: (data: VerificationRequest, params: RequestParams = {}) =>
      this.request<VerificationResponse, void>({
        path: `/user-verification`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  sectors = {
    /**
     * @description Get sectors information
     *
     * @tags Sector
     * @name SectorsList
     * @request GET:/sectors
     * @secure
     */
    sectorsList: (
      query?: {
        page?: number;
        limit?: number;
        searchValue?: string;
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/sectors`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Add new sector to farm
     *
     * @tags Sector
     * @name SectorsCreate
     * @request POST:/sectors
     * @secure
     */
    sectorsCreate: (data: PostFarmSectorsSchema, params: RequestParams = {}) =>
      this.request<PostFarmSectorsResponse, void>({
        path: `/sectors`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  sector = {
    /**
     * @description Get sector information for SectorDetail View
     *
     * @tags Sector
     * @name SectorDetail
     * @request GET:/sector/{id}
     * @secure
     */
    sectorDetail: (id: string, params: RequestParams = {}) =>
      this.request<SectorByIdSchemaResponse, void>({
        path: `/sector/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Update a general information of sector
     *
     * @tags Sector
     * @name SectorUpdate
     * @request PUT:/sector/{id}
     * @secure
     */
    sectorUpdate: (id: string, data: UpdateSectorSchema, params: RequestParams = {}) =>
      this.request<UpdateSectorResponse, void>({
        path: `/sector/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  sectorsKmz = {
    /**
     * @description Update sectors based on KMZ
     *
     * @tags Sector
     * @name SectorsKmzUpdate
     * @request PUT:/sectors-kmz
     * @secure
     */
    sectorsKmzUpdate: (data: any, params: RequestParams = {}) =>
      this.request<PutSectorsKMZResponse, void>({
        path: `/sectors-kmz`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  workOrders = {
    /**
     * @description Get work orders information
     *
     * @tags WorkOrder
     * @name WorkOrdersList
     * @request GET:/workOrders
     * @secure
     */
    workOrdersList: (
      query?: {
        page?: number;
        limit?: number;
        searchValue?: string;
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetWorkOrdersPerPageResponse, void>({
        path: `/workOrders`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  dispatchGuides = {
    /**
     * @description Get all dispatch guides information from accountId
     *
     * @tags WorkOrder
     * @name AccountDetail
     * @request GET:/dispatchGuides/account/{accountId}
     * @secure
     */
    accountDetail: (accountId?: string, params: RequestParams = {}) =>
      this.request<GetDispatchGuidesByAccountIdResponse, void>({
        path: `/dispatchGuides/account/${accountId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  workOrdersFromSoftland = {
    /**
     * @description Updates or creates work orders from softland
     *
     * @tags WorkOrder
     * @name WorkOrdersFromSoftlandUpdate
     * @request PUT:/workOrdersFromSoftland
     * @secure
     */
    workOrdersFromSoftlandUpdate: (data: PutWorkOrdersFromSoftlandSchema, params: RequestParams = {}) =>
      this.request<PutWorkOrdersFromSoftlandResponse, void>({
        path: `/workOrdersFromSoftland`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  companies = {
    /**
     * @description Get companies information
     *
     * @tags Company
     * @name CompaniesList
     * @request GET:/companies
     * @secure
     */
    companiesList: (
      query?: {
        page?: number;
        limit?: number;
        searchValue?: string;
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCompaniesPerPageResponse, void>({
        path: `/companies`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get company information
     *
     * @tags Company
     * @name CompaniesDetail
     * @request GET:/companies/{id}
     * @secure
     */
    companiesDetail: (id?: string, params: RequestParams = {}) =>
      this.request<GetCompanyByIdResponse, void>({
        path: `/companies/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Updates all the companies' information from softland
     *
     * @tags Company
     * @name SoftlandGetAllCompaniesUpdate
     * @request PUT:/companies/softland/get-all-companies
     * @secure
     */
    softlandGetAllCompaniesUpdate: (params: RequestParams = {}) =>
      this.request<PutCompaniesSoftlandToMongoResponse, void>({
        path: `/companies/softland/get-all-companies`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  cultivationCycle = {
    /**
     * @description Create a new cultivation cycle
     *
     * @tags CultivationCycle
     * @name CultivationCycleCreate
     * @request POST:/cultivationCycle
     * @secure
     */
    cultivationCycleCreate: (data: PostCultivationCycleSchema, params: RequestParams = {}) =>
      this.request<PostCultivationCycleResponse, void>({
        path: `/cultivationCycle`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Edits details of CultivationCycle (except from Phen. States)
     *
     * @tags CultivationCycle
     * @name CultivationCycleUpdate
     * @request PUT:/cultivationCycle
     * @secure
     */
    cultivationCycleUpdate: (data: UpdateCultivationCycleSchema, params: RequestParams = {}) =>
      this.request<UpdateCultivationCycleResponse, void>({
        path: `/cultivationCycle`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description delete a CultivationCycle
     *
     * @tags Sector
     * @name CultivationCycleDelete
     * @request DELETE:/cultivationCycle/{id}
     * @secure
     */
    cultivationCycleDelete: (id: string, params: RequestParams = {}) =>
      this.request<DeleteCultivationCycleResponse, void>({
        path: `/cultivationCycle/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  cultivationCycleSector = {
    /**
     * @description Get CultivationCycles of Sector
     *
     * @tags Sector, CultivationCycle
     * @name CultivationCycleSectorDetail
     * @request GET:/cultivationCycle-sector/{id}
     * @secure
     */
    cultivationCycleSectorDetail: (id: string, params: RequestParams = {}) =>
      this.request<GetCultivationCyclesBySectorResponse, void>({
        path: `/cultivationCycle-sector/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  phenologicalStateCultivationCycle = {
    /**
     * @description add a PhenologicalState to a CultivationCycle
     *
     * @tags PhenologicalState, CultivationCycle
     * @name PhenologicalStateCultivationCycleUpdate
     * @request PUT:/phenologicalState-cultivationCycle/{id}
     * @secure
     */
    phenologicalStateCultivationCycleUpdate: (
      id: string,
      data: PutPhenologicalStateSchema,
      params: RequestParams = {},
    ) =>
      this.request<PutPhenologicalStateResponse, void>({
        path: `/phenologicalState-cultivationCycle/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Deletes a Phenological State
     *
     * @tags PhenologicalState, CultivationCycle
     * @name PhenologicalStateCultivationCycleDelete
     * @request DELETE:/phenologicalState-cultivationCycle
     * @secure
     */
    phenologicalStateCultivationCycleDelete: (data: DeletePhenologicalStateSchema, params: RequestParams = {}) =>
      this.request<DeletePhenologicalStateResponse, void>({
        path: `/phenologicalState-cultivationCycle`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  devices = {
    /**
     * @description Get devices information
     *
     * @tags Device
     * @name DevicesList
     * @request GET:/devices
     * @secure
     */
    devicesList: (
      query?: {
        page?: number;
        limit?: number;
        searchValue?: string;
        sort?: string;
        accountId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetDevicesPerPageResponse, void>({
        path: `/devices`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Creates/associates a new device to a farm
     *
     * @tags Device
     * @name DevicesCreate
     * @request POST:/devices
     * @secure
     */
    devicesCreate: (
      data: {
        files?: File[];
        /** The JSON string containing additional data such as farmId and coordinates */
        data?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PostDevicesResponse, void>({
        path: `/devices`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Get device information
     *
     * @tags Device
     * @name DevicesDetail
     * @request GET:/devices/{id}
     * @secure
     */
    devicesDetail: (id?: string, params: RequestParams = {}) =>
      this.request<GetDeviceByIdResponse, void>({
        path: `/devices/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Update device information
     *
     * @tags Device
     * @name DevicesUpdate
     * @request PUT:/devices/{id}
     * @secure
     */
    devicesUpdate: (data: PutDeviceSchema, id?: string, params: RequestParams = {}) =>
      this.request<PutDeviceResponse, void>({
        path: `/devices/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Updates all the devices' information from softland
     *
     * @tags Device
     * @name SoftlandGetAllDevicesUpdate
     * @request PUT:/devices/softland/get-all-devices
     * @secure
     */
    softlandGetAllDevicesUpdate: (params: RequestParams = {}) =>
      this.request<PutDevicesSoftlandToMongoResponse, void>({
        path: `/devices/softland/get-all-devices`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get devices information of a particular farm
     *
     * @tags Device
     * @name FarmsDetail
     * @request GET:/devices/farms/{farmId}
     * @secure
     */
    farmsDetail: (farmId?: string, params: RequestParams = {}) =>
      this.request<GetDevicesByFarmIdResponse, void>({
        path: `/devices/farms/${farmId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  farms = {
    /**
     * @description Get farms information
     *
     * @tags Farm
     * @name FarmsList
     * @request GET:/farms
     * @secure
     */
    farmsList: (
      query?: {
        page?: number;
        limit?: number;
        searchValue?: string;
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetFarmsPerPageResponse, void>({
        path: `/farms`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Create new farm
     *
     * @tags Farm
     * @name FarmsCreate
     * @request POST:/farms
     * @secure
     */
    farmsCreate: (data: any, params: RequestParams = {}) =>
      this.request<PostFarmsResponse, void>({
        path: `/farms`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get all farms based on account id
     *
     * @tags Farm
     * @name AccountsDetail
     * @request GET:/farms/accounts/{accountId}
     * @secure
     */
    accountsDetail: (accountId: string, params: RequestParams = {}) =>
      this.request<GetFarmsByAccountIdResponse, void>({
        path: `/farms/accounts/${accountId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  images = {
    /**
     * @description Get list of images from Google Storage
     *
     * @tags Google Storage Images
     * @name ListList
     * @request GET:/images/list
     * @secure
     */
    listList: (
      query?: {
        deviceId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetGoogleStorageImagesResponse, void>({
        path: `/images/list`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Upload image to Google Storage
     *
     * @tags Google Storage Images
     * @name UploadCreate
     * @request POST:/images/upload
     * @secure
     */
    uploadCreate: (
      data: {
        /** @format binary */
        file: File;
        deviceId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UploadImageToStorageResponse, void>({
        path: `/images/upload`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Delete image from Google Storage
     *
     * @tags Google Storage Images
     * @name DeleteDelete
     * @request DELETE:/images/delete
     * @secure
     */
    deleteDelete: (
      query?: {
        deviceId?: string;
        fileName?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DeleteImageFromStorageResponse, void>({
        path: `/images/delete`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  health = {
    /**
     * @description Responds if the app is up and running
     *
     * @tags Healthcheck
     * @name HealthList
     * @request GET:/health
     * @secure
     */
    healthList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/health`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  users = {
    /**
     * @description Get users information
     *
     * @tags User
     * @name UsersList
     * @request GET:/users
     * @secure
     */
    usersList: (
      query?: {
        page?: number;
        limit?: number;
        searchValue?: string;
        sort?: string;
        filterAccount?: string;
        filterFarm?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetUsersPerPageResponse, void>({
        path: `/users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Creates a user.
     *
     * @tags User
     * @name UsersCreate
     * @request POST:/users
     * @secure
     */
    usersCreate: (data: PostUserSchema, params: RequestParams = {}) =>
      this.request<PostUserResponse, void>({
        path: `/users`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Update a user information
     *
     * @tags User
     * @name UsersUpdate
     * @request PUT:/users/{id}
     * @secure
     */
    usersUpdate: (id: string, data: UpdateUserSchema, params: RequestParams = {}) =>
      this.request<UpdateUserResponse, void>({
        path: `/users/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve a user by its ID. Needs to be accessible to every user,
     *
     * @tags User
     * @name UsersDetail
     * @request GET:/users/{id}
     * @secure
     */
    usersDetail: (id: string, data?: any, params: RequestParams = {}) =>
      this.request<GetUserResponse, void>({
        path: `/users/${id}`,
        method: "GET",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Delete a users array by IDs.
     *
     * @tags User
     * @name UsersDelete
     * @request DELETE:/users/{userIds}
     * @secure
     */
    usersDelete: (userIds: string[], params: RequestParams = {}) =>
      this.request<DeleteUserResponse, void>({
        path: `/users/${userIds}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * @description get a current user information
     *
     * @tags User
     * @name UserList
     * @request GET:/user
     * @secure
     */
    userList: (data?: any, params: RequestParams = {}) =>
      this.request<GetUserResponse, void>({
        path: `/user`,
        method: "GET",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  varieties = {
    /**
     * @description Get varieties information
     *
     * @tags Variety
     * @name VarietiesList
     * @request GET:/varieties
     * @secure
     */
    varietiesList: (
      query?: {
        page?: number;
        limit?: number;
        searchValue?: string;
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetVarietiesPerPageResponse, void>({
        path: `/varieties`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Creates a variety.
     *
     * @tags Variety
     * @name VarietiesCreate
     * @request POST:/varieties
     * @secure
     */
    varietiesCreate: (data: PostVarietySchema, params: RequestParams = {}) =>
      this.request<PostVarietyResponse, void>({
        path: `/varieties`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get specific variety information
     *
     * @tags Variety
     * @name VarietiesDetail
     * @request GET:/varieties/{id}
     * @secure
     */
    varietiesDetail: (id?: string, params: RequestParams = {}) =>
      this.request<GetVarietyByIdResponse, void>({
        path: `/varieties/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Modifies a variety.
     *
     * @tags Variety
     * @name VarietiesUpdate
     * @request PUT:/varieties/{id}
     * @secure
     */
    varietiesUpdate: (id: string, data: PutVarietySchema, params: RequestParams = {}) =>
      this.request<PutVarietyResponse, void>({
        path: `/varieties/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Delete an variety by ID.
     *
     * @tags Variety
     * @name VarietiesDelete
     * @request DELETE:/varieties/{id}
     * @secure
     */
    varietiesDelete: (id: string, params: RequestParams = {}) =>
      this.request<DeleteVarietyResponse, void>({
        path: `/varieties/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  species = {
    /**
     * @description Get species information
     *
     * @tags Species
     * @name SpeciesList
     * @request GET:/species
     * @secure
     */
    speciesList: (params: RequestParams = {}) =>
      this.request<GetSpeciesResponse, void>({
        path: `/species`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
