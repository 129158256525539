import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Divider,
  IconButton,
  Autocomplete,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { useDispatch, useSelector } from 'react-redux';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import useStyles from '../styles';
import { ReduxState } from '../../../../types';
import { setCreateUserData } from '../../../../actions/userActions';
import apiClient from '../../../../requests/api/apiClient';
import { FetchError } from '../../../types';

type LocalAccount = {
  dummyId: string,
  accountId?: string,
  farms?: Array<{ id: string, name: string }>
};

type AccountList = {
  id: string,
  name: string,
  farms?: { id: string, name: string }[]
}[];

type FarmList = {
  id: string,
  name: string,
}[];

function UserAccountAssociation() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReduxState) => state.user);
  const { enqueueSnackbar } = useSnackbar();
  const [accountsList, setAccountsList] = useState<AccountList>([]);
  const [farmList, setFarmsList] = useState<FarmList>([]);
  const [localAccounts, setLocalAccounts] = useState<LocalAccount[]>([]);
  const [selectedValues, setSelectedValues] = useState<{
    dummyId: string,
    account: { id: string, name: string },
    farm: Array<{ id: string, name: string }>,
  }>({
    dummyId: uuidv4(),
    account: { id: '', name: '' },
    farm: [],
  });

  useEffect(() => {
    if (user?.accounts) {
      const processedAccounts = user.accounts.map((elem) => ({
        ...elem,
        dummyId: uuidv4(),
        farms: elem.farms?.map((farmId) => {
          const farm = farmList.find((f) => f.id === farmId);
          return farm || { id: farmId, name: '' };
        }),
      }));
      setLocalAccounts(processedAccounts);
    }
  }, [user, farmList]);

  useEffect(() => {
    const getAccountsInformation = async () => {
      try {
        const accountResponse = await apiClient.accounts.accountsList();
        const farmResponse = await apiClient.farms.farmsList();

        const auxAccountList = accountResponse?.data?.accounts?.map((account) => ({
          id: account.id as string,
          name: account.name as string,
          farms: account.farms?.map((farm) => ({
            id: farm.id as string,
            name: farm.name,
          })),
        })) || [];
        const auxFarmResponse = farmResponse?.data?.farms?.map((farm) => ({
          id: farm.id as string,
          name: farm.name as string,
        })) || [];

        setAccountsList(auxAccountList);
        setFarmsList(auxFarmResponse);
      } catch (err) {
        const { error } = err as FetchError;
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    };

    getAccountsInformation();
  }, []);

  const handleChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: { id: string, name: string } | { id: string, name: string }[] | null,
    dummyId: string,
    source: string,
  ) => {
    const auxLocalAccounts = [...localAccounts];
    const selectedAccount = auxLocalAccounts.find((elem) => elem.dummyId === dummyId);

    if (!selectedAccount) {
      enqueueSnackbar('Error al encontrar la cuenta a cambiar.', { variant: 'error' });
      return;
    }

    if (source === 'account') {
      const auxValue = value as { id: string, name: string };
      selectedAccount.accountId = auxValue.id;
      setSelectedValues((prevState) => ({
        ...prevState,
        account: { id: auxValue?.id ?? '', name: auxValue?.name ?? '' },
      }));
      dispatch(setCreateUserData({
        accounts: auxLocalAccounts
          .map((elem) => ({
            accountId: elem.accountId as string,
            farms: elem?.farms
              ?.map((farm) => farm.id),
          })),
      }));
    } else {
      const auxValue = value as { id: string, name: string }[];
      setSelectedValues((prevState) => ({
        ...prevState,
        farm: auxValue,
      }));

      selectedAccount.farms = auxValue;

      dispatch(setCreateUserData({
        accounts: auxLocalAccounts
          .map((elem) => ({
            accountId: elem.accountId as string,
            farms: elem?.farms?.map((farm) => farm.id),
          })),
      }));
    }

    setLocalAccounts(auxLocalAccounts);
  };

  const handleAddAccount = async () => {
    if (localAccounts.length === 0 || (
      localAccounts[localAccounts.length - 1]?.accountId)) {
      const newUuid = uuidv4();
      setSelectedValues({
        dummyId: newUuid,
        account: { id: '', name: '' },
        farm: [],
      });
      setLocalAccounts((prevState) => ([...prevState, { dummyId: newUuid }]));
    }
  };

  const handleRemoveAccount = (dummyId: string) => {
    const auxLocalAccounts = [...localAccounts];

    const remainingAccounts = auxLocalAccounts.filter((elem) => elem.dummyId !== dummyId);

    setLocalAccounts(remainingAccounts);
    dispatch(setCreateUserData({
      accounts: remainingAccounts
        .map((elem) => ({
          accountId: elem.accountId as string, farms: elem?.farms?.map((farm) => farm.id),
        })),
    }));
  };

  return (
    <Card className={classes.cardContainer}>
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid item xs={12}>
            <Typography
              variant='h4'
              color='primary'
            >
              Acceso a cuentas
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={5}>
            Nombre
          </Grid>
          <Grid item xs={5} >
            Campos
          </Grid>
          <Grid item xs={2} >
            Quitar
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {localAccounts.map((elem) => (
            <React.Fragment key={elem.dummyId}>
              <Grid item xs={5}>
                <Autocomplete
                  fullWidth
                  id={`account-list-autocomplete-${elem.dummyId}`}
                  size="small"
                  renderInput={(params) => <TextField {...params} variant='standard' label="Cuenta" />}
                  options={accountsList.filter(
                    (account) => !localAccounts.some(
                      (localAccount) => localAccount.accountId === account.id,
                    ),
                  ) || []} // filter accounts that are already selected
                  value={accountsList.find((account) => account.id === elem.accountId)
                    ?? selectedValues.account}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  getOptionLabel={(option) => option?.name ?? ''}
                  // disabled={elem.dummyId !== selectedValues.dummyId}
                  onChange={(e, value) => handleChange(e, value, elem.dummyId, 'account')}
                />
              </Grid>
              <Grid item xs={5}>
                <Autocomplete
                  multiple
                  fullWidth
                  id={`farm-list-autocomplete-${elem.dummyId}`}
                  size="small"
                  renderInput={(params) => <TextField {...params} variant='standard' label="Campo" />}
                  options={farmList || []}
                  value={elem?.farms ?? selectedValues.farm}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  getOptionLabel={(option) => option?.name ?? ''}
                  // disabled={elem.dummyId !== selectedValues.dummyId}
                  onChange={(e, value) => handleChange(e, value, elem.dummyId, 'farm')}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={() => handleRemoveAccount(elem.dummyId)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12} textAlign='center'>
            <IconButton onClick={handleAddAccount}>
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent >
    </Card>
  );
}

export default UserAccountAssociation;
