import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { useSnackbar } from 'notistack';

import { UserSchema } from '../../../../requests/api/apiTypes';
import Page from '../../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import useStyles from '../styles';
import apiClient from '../../../../requests/api/apiClient';
import { FetchError } from '../../../types';

type AccountList = {
  id: string,
  name: string,
  farms?: { id: string, name: string }[]
}[];

function UserListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [users, setUsers] = useState<UserSchema[]>([]);
  const [accountsList, setAccountsList] = useState<AccountList>([]);
  const [farmList, setFarmsList] = useState<{
    id: string,
    name: string
  }[]>([]);
  const [countUsers, setCountUsers] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState<number>(5);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState('updatedAt|DESC');
  const [filter, setFilters] = useState<{
    account: { id: string, name: string },
    farm: { id: string, name: string }
  }>({
    account: { id: '', name: '' },
    farm: { id: '', name: '' },
  });

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await apiClient.users.usersList({
          page,
          limit,
          searchValue: query,
          sort,
          filterAccount: filter?.account?.id ?? undefined,
          filterFarm: filter?.farm.id,
        });

        setUsers(response.data.users || []);
        setCountUsers(response.data.totalUsers || 0);
      } catch (err) {
        const { error } = err as FetchError;
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    };
    getUsers();
  }, [sort, limit, page, query, filter?.account?.id, filter?.farm?.id]);

  useEffect(() => {
    const getAccountsInformation = async () => {
      try {
        const accountResponse = await apiClient.accounts.accountsList();
        const farmResponse = await apiClient.farms.farmsList();

        const auxAccountList = accountResponse?.data?.accounts?.map((elem) => ({
          id: elem.id as string,
          name: elem.name as string,
          farms: elem.farms?.map((elem2) => ({
            id: elem2.id as string,
            name: elem2.name,
          })),
        })) || [];
        const auxFarmResponse = farmResponse?.data?.farms?.map((elem) => ({
          id: elem.id as string,
          name: elem.name as string,
        })) || [];

        setAccountsList(auxAccountList);
        setFarmsList(auxFarmResponse);
      } catch (err) {
        const { error } = err as FetchError;
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    };

    getAccountsInformation();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Usuarios"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <Results
            users={users}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            query={query}
            setQuery={setQuery}
            sort={sort}
            setSort={setSort}
            countUsers={countUsers}
            accountsList={accountsList}
            farmList={farmList}
            filter={filter}
            setFilter={setFilters}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default UserListView;
