import {
  Typography,
  IconButton,
  Divider,
  TextField,
  MenuItem,
  Box,
  SvgIcon,
  Checkbox,
  FormControlLabel,
  Chip,
} from '@mui/material';
import React, { useRef } from 'react';
import { Close as CloseIcon, UploadFile as UploadFileIcon } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import CustomButton from '../../../../../components/General/CustomButton';
import useStyles from '../../styles';
import { DeviceInfo, ErrorState } from './types';
import { ReduxState } from '../../../../../types';
import { DeviceSchema } from '../../../../../requests/api/apiTypes';

const AddDevicesFlowSecondStep = ({
  deviceInfo,
  setDeviceInfo,
  devicesOptions,
  isSensor,
  hasDispatchGuide,
  handleCloseModal,
  handleStep,
  error,
  setError,
  files,
  setFiles,
}: {
  deviceInfo: DeviceInfo,
  setDeviceInfo: React.Dispatch<React.SetStateAction<DeviceInfo>>,
  devicesOptions: DeviceSchema[] | null,
  isSensor: boolean,
  hasDispatchGuide: boolean,
  handleCloseModal: VoidFunction,
  handleStep: (step: 'firstStep' | 'secondStep' | 'thirdStep') => void,
  error: ErrorState,
  setError: React.Dispatch<React.SetStateAction<ErrorState>>,
  files: Array<{ id: string, file: File }>,
  setFiles: React.Dispatch<React.SetStateAction<Array<{ id: string, file: File }>>>
}) => {
  const classes = useStyles();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { allFarmDevices } = useSelector((state: ReduxState) => state.farmSector);

  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    source: string,
  ) => {
    if (e.target.type === 'checkbox') {
      setDeviceInfo((prevState) => ({
        ...prevState,
        [source]: (e.target as HTMLInputElement).checked,
      }));
      // specific for nodeChip
      setError((prevState) => ({ ...prevState, nodeChip: '' }));
    } else {
      setDeviceInfo((prevState) => ({ ...prevState, [source]: e.target.value }));
      setError((prevState) => ({ ...prevState, [source]: '' }));
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files).map((file) => ({
        id: uuidv4(),
        file,
      }));
      setFiles((prevState) => ([...prevState, ...filesArray]));
    }
  };

  const handleDeleteFile = (fileId: string) => {
    const remainingFiles = files.filter((relFile) => relFile.id !== fileId);
    setFiles(remainingFiles);
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Box display='flex' flexDirection='column' flex='1'>
      <Box className={classes.uploadAddDevicesHeader}>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.uploadAddDevicesHeaderTitle}
        >
          <span className={classes.activePageUnderlined}>
            {isSensor ? 'Agregar sensor' : 'Agregar nodo'}
          </span>
        </Typography>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider className={classes.divider} />
      {(isSensor && !hasDispatchGuide)
        && <>
          <Box className={classes.textFieldsContainer}>
            <TextField
              label='Tipo (*)'
              variant='standard'
              select
              fullWidth
              className={classes.singleLeftTextfield}
              value={deviceInfo.sensorType}
              onChange={(e) => handleValueChange(e, 'sensorType')}
              error={error.sensorType !== ''}
              helperText={error.sensorType !== '' && error.sensorType}
            >
              {/* CAMBIAR DESPOUES - REVISAR */}
              <MenuItem value={'node'}>
                Nodo
              </MenuItem>
              <MenuItem value={'sensor'}>
                Sensor
              </MenuItem>
            </TextField>
            <TextField
              label='Marca'
              variant='standard'
              select
              fullWidth
              value={deviceInfo.brand}
              onChange={(e) => handleValueChange(e, 'brand')}
            >
              {/* CAMBIAR DESPOUES - REVISAR */}
              <MenuItem value='brand1'>
                Marca 1
              </MenuItem>
              <MenuItem value='brand2'>
                Marca 2
              </MenuItem>
            </TextField>
          </Box>
          <Box className={classes.textFieldsContainer}>
            <TextField
              label='Modelo - nombre'
              variant='standard'
              fullWidth
              select
              className={classes.singleLeftTextfield}
              value={deviceInfo.model}
              onChange={(e) => handleValueChange(e, 'model')}
            >
              {/* CAMBIAR DESPOUES - REVISAR */}
              <MenuItem value={'sensor1'}>
                Sensor 1
              </MenuItem>
              <MenuItem value={'sensor2'}>
                Sensor 2
              </MenuItem>
            </TextField>
            <TextField
              label='ID (*)'
              variant='standard'
              fullWidth
              value={deviceInfo.cdtecDeviceId}
              onChange={(e) => handleValueChange(e, 'cdtecDeviceId')}
              error={error.cdtecDeviceId !== ''}
              helperText={error.cdtecDeviceId !== '' && error.cdtecDeviceId}
            />
          </Box>
          <Box className={classes.singleTextFieldsContainer}>
            <TextField
              label='Nodo'
              select
              variant='standard'
              fullWidth
              value={deviceInfo.nodeId}
              onChange={(e) => handleValueChange(e, 'nodeId')}
            >
              {allFarmDevices.filter((device) => device.deviceType === 'node').map((device) => (
                <MenuItem key={device.id} value={device.id}>
                  {device.deviceModel?.name}
                </MenuItem>

              ))}
            </TextField>
          </Box>
          <Box className={classes.textFieldsContainer}>
            <TextField
              label='Comentario'
              variant='standard'
              fullWidth
              value={deviceInfo.comment}
              onChange={(e) => handleValueChange(e, 'comment')}
            />
          </Box>
        </>
      }
      {(isSensor && hasDispatchGuide)
        && <>
          <Box className={classes.textFieldsContainer}>
            <TextField
              label='Sensor'
              variant='standard'
              select
              fullWidth
              value={deviceInfo.sensorId}
              onChange={(e) => handleValueChange(e, 'sensorId')}
            >
              {devicesOptions && devicesOptions.map((device) => (
                <MenuItem key={device.id} value={device.id}>
                  {device.deviceModel?.name}
                </MenuItem>

              ))}
            </TextField>
          </Box>
          <Box className={classes.textFieldsContainer}>
            <TextField
              label='Nodo'
              variant='standard'
              select
              fullWidth
              value={deviceInfo.nodeId}
              onChange={(e) => handleValueChange(e, 'nodeId')}
            >
               {allFarmDevices.filter((device) => device.deviceType === 'node').map((device) => (
                <MenuItem key={device.id} value={device.id}>
                  {device.deviceModel?.name}
                </MenuItem>
               ))}
            </TextField>
          </Box>
          <Box className={classes.textFieldsContainer}>
            <TextField
              label='Comentario'
              variant='standard'
              fullWidth
              value={deviceInfo.comment}
              onChange={(e) => handleValueChange(e, 'comment')}
            />
          </Box>
        </>}
      {(!isSensor && !hasDispatchGuide) && <>
        <Box className={classes.textFieldsContainer}>
          <TextField
            label='Tipo (*)'
            variant='standard'
            select
            fullWidth
            className={classes.singleLeftTextfield}
            value={deviceInfo.nodeType}
            onChange={(e) => handleValueChange(e, 'nodeType')}
            error={error.nodeType !== ''}
            helperText={error.nodeType !== '' && error.nodeType}
          >
            {/* CAMBIAR DESPOUES - REVISAR */}
            <MenuItem value={'riego'}>
              Riego
            </MenuItem>
            <MenuItem value={'plant'}>
              Planta
            </MenuItem>
          </TextField>
          <TextField
            label='Marca'
            variant='standard'
            select
            fullWidth
            value={deviceInfo.brand}
            onChange={(e) => handleValueChange(e, 'brand')}
          >
            {/* CAMBIAR DESPOUES - REVISAR */}
            <MenuItem value={'brand1'}>
              Marca 1
            </MenuItem>
            <MenuItem value={'brand2'}>
              Marca 2
            </MenuItem>
          </TextField>
        </Box>
        <Box className={classes.textFieldsContainer}>
          <TextField
            label='Modelo - nombre'
            variant='standard'
            fullWidth
            select
            className={classes.singleLeftTextfield}
            value={deviceInfo.model}
            onChange={(e) => handleValueChange(e, 'model')}
          >
            {/* CAMBIAR DESPOUES - REVISAR */}
            <MenuItem value={'sensor1'}>
              Sensor 1
            </MenuItem>
            <MenuItem value={'sensor2'}>
              Sensor 2
            </MenuItem>
          </TextField>
          <TextField
            label='ID (*)'
            variant='standard'
            fullWidth
            value={deviceInfo.cdtecDeviceId}
            onChange={(e) => handleValueChange(e, 'cdtecDeviceId')}
            error={error.cdtecDeviceId !== ''}
            helperText={error.cdtecDeviceId !== '' && error.cdtecDeviceId}
          />
        </Box>
        <Box className={classes.textFieldsContainer}>
          <Box className={classes.checkboxContainer}>
            <FormControlLabel
              control={<Checkbox
                checked={deviceInfo.isGatewayNode}
                onChange={(e) => handleValueChange(e, 'isGatewayNode')}
              />}
              label="Nodo Gateway" />
          </Box>
          <TextField
            label='Chip'
            variant='standard'
            fullWidth
            disabled={!deviceInfo.isGatewayNode}
            value={deviceInfo.nodeChip}
            onChange={(e) => handleValueChange(e, 'nodeChip')}
            error={error.nodeChip !== ''}
            helperText={error.nodeChip !== '' && error.nodeChip}
          />
        </Box>
        <Box className={classes.textFieldsContainer}>
          <TextField
            label='Comentario'
            variant='standard'
            fullWidth
            value={deviceInfo.comment}
            onChange={(e) => handleValueChange(e, 'comment')}
          />
        </Box>
      </>}
      {(!isSensor && hasDispatchGuide) && <>
        <Box className={classes.textFieldsContainer}>
          <TextField
            label='Nodo'
            variant='standard'
            select
            fullWidth
            value={deviceInfo.nodeId}
            onChange={(e) => handleValueChange(e, 'nodeId')}
          >
            {devicesOptions && devicesOptions.map((device) => (
              <MenuItem key={device.id} value={device.id}>
                {device.deviceModel?.name}
              </MenuItem>

            ))}
          </TextField>
        </Box>
        <Box className={classes.textFieldsContainer}>
          <Box className={classes.checkboxContainer}>
            <FormControlLabel
              control={<Checkbox
                checked={deviceInfo.isGatewayNode}
                onChange={(e) => handleValueChange(e, 'isGatewayNode')}
              />}
              label="Nodo Gateway" />
          </Box>
          <TextField
            label='Chip'
            variant='standard'
            fullWidth
            disabled={!deviceInfo.isGatewayNode}
            value={deviceInfo.nodeChip}
            onChange={(e) => handleValueChange(e, 'nodeChip')}
            error={error.nodeChip !== ''}
            helperText={error.nodeChip !== '' && error.nodeChip}
          />
        </Box>
        <Box className={classes.textFieldsContainer}>
          <TextField
            label='Comentario'
            variant='standard'
            fullWidth
            value={deviceInfo.comment}
            onChange={(e) => handleValueChange(e, 'comment')}
          />
        </Box>
      </>}
      <Box role='button' onClick={handleClick} className={classes.uploadImagesContainer}>
        <SvgIcon sx={{ marginRight: '10px' }}>
          <UploadFileIcon />
        </SvgIcon>
        <Typography
          variant='body1'
        >
          SUBIR IMAGENES
        </Typography>
        <input
          ref={fileInputRef}
          type="file"
          multiple
          style={{ display: 'none' }}
          accept="image/*"
          name='files'
          onChange={handleFileChange}
        />
      </Box>
      <Box mt={1} mb={1}>
        {files && files.length > 0 && <>
          {files.map((relFile) => <Chip
            key={relFile.id}
            label={relFile.file.name}
            onDelete={() => handleDeleteFile(relFile.id)}
          />)}
        </>}
        {files && files.length === 0
          && <Typography mt={1} mb={1}>No hay imágenes seleccionadas</Typography>}
      </Box>
      <Box className={classes.buttonContainer}>
        <Box className={classes.singleButtonContainer} marginRight='20px'>
          <CustomButton buttonText='anterior' onClick={() => handleStep('firstStep')} />
        </Box>
        <Box className={classes.singleButtonContainer}>
          <CustomButton buttonText='siguiente' onClick={() => handleStep('thirdStep')} />
        </Box>
      </Box>
    </Box>
  );
};

export default AddDevicesFlowSecondStep;
