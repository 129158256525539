/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Close as CloseIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { v4 as uuidv4 } from 'uuid';
import useStyles from '../styles';
import CustomButton from '../../../components/General/CustomButton';
import useFetchApi from '../../../hooks/useFetchApi';
import { GetSpeciesResponse, GetVarietyByIdResponse, VarietySchema } from '../../../requests/api/apiTypes';

type LocalPhenologicalStates = {
  id: string,
  name: string,
  iniDate: string | null,
  endDate: string | null,
  disabled?: boolean,
};

type Errors = {
  [key: string]: {
    message: string,
    active: boolean,
  }
};

const PhenologicalStateComponent = ({
  elem, onValueChange, error, removePhenologicalState,
}: {
  elem: LocalPhenologicalStates,
  onValueChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, source: string,
  ) => void,
  error?: Errors,
  removePhenologicalState: (item: string) => void,
}) => (
  <TableRow >
    <TableCell>
      <TextField
        label='Nombre'
        name="name"
        disabled={elem.disabled}
        variant='standard'
        value={elem.name ?? ''}
        onChange={(e) => onValueChange(e, 'name')}
        type='text'
        required
        error={error?.name.active}
        helperText={error?.name.active ? error.name.message : (elem.disabled ? '' : ' ')}
      />
    </TableCell>
    <TableCell>
      <TextField
        disabled={elem.disabled}
        variant='standard'
        label=' '
        value={elem.iniDate ?? ''}
        onChange={(e) => onValueChange(e, 'iniDate')}
        type='date'
        error={error?.iniDate.active}
        helperText={error?.iniDate.active ? error.iniDate.message : (elem.disabled ? '' : ' ')}
      />
    </TableCell>
    <TableCell>
      <TextField
        disabled={elem.disabled}
        variant='standard'
        label=' '
        value={elem.endDate ?? ''}
        onChange={(e) => onValueChange(e, 'endDate')}
        type='date'
        error={error?.endDate.active}
        helperText={error?.endDate.active ? error.endDate.message : (elem.disabled ? '' : ' ')}
      />
    </TableCell>
    <TableCell >
      <IconButton onClick={() => removePhenologicalState(elem.id)}>
        <DeleteIcon />
      </IconButton>
    </TableCell>
  </TableRow>
);

const UpsertModalVarietyContent = ({
  handleCloseModal,
  handleUpsertFunction,
  id,
}: {
  handleCloseModal: VoidFunction,
  handleUpsertFunction: ({
    name,
    species,
    phenologicalStates,
    id,
  }: {
    name: string,
    species: string,
    phenologicalStates: VarietySchema['phenologicalStates'],
    id?: string,
  }) => void,
  id?: string,
}) => {
  const classes = useStyles();

  const [varietyName, setVarietyName] = useState('');
  const [speciesName, setSpeciesName] = useState('');
  const [localPhenologicalStates, setLocalPhenologicalStates] = useState<LocalPhenologicalStates[]
  >([]);
  const [activePhenologicalState, setActivePhenologicalState] = useState<LocalPhenologicalStates>({
    id: uuidv4(),
    name: '',
    iniDate: null,
    endDate: null,
  });
  const [beenUpdated, setBeenUpdated] = useState(false);
  const [error, setError] = useState({
    varietyName: {
      message: '',
      active: false,
    },
    speciesName: {
      message: '',
      active: false,
    },
    name: {
      message: '',
      active: false,
    },
    iniDate: {
      message: '',
      active: false,
    },
    endDate: {
      message: '',
      active: false,
    },
  });

  const { data } = useFetchApi<GetSpeciesResponse>({
    modelName: 'species',
    modelEndpoint: 'speciesList',
  });

  const entityToModify = id && useFetchApi<GetVarietyByIdResponse>({
    modelName: 'varieties',
    modelEndpoint: 'varietiesDetail',
    id,
  });

  useEffect(() => {
    if (id && entityToModify && entityToModify.data && !beenUpdated) {
      const auxPhenologicalStates = entityToModify.data.variety?.phenologicalStates
        ?.map((elem) => ({
          iniDate: elem.iniDate,
          endDate: elem.endDate,
          name: elem.name,
          id: uuidv4(),
          disabled: true,
        })) ?? [];
      setBeenUpdated(true);
      setVarietyName(entityToModify.data.variety?.name ?? '');
      setSpeciesName(entityToModify.data.variety?.species ?? '');
      setLocalPhenologicalStates(auxPhenologicalStates);
    }
  }, [entityToModify]);

  const activeFieldsCheck = () => {
    // Case where all three are empty, it should be able to be created
    if (activePhenologicalState.name.trim() === '' && !activePhenologicalState.iniDate && !activePhenologicalState.endDate) {
      return false;
    }

    // Otherwise
    if (activePhenologicalState.name.trim() === '') {
      setError((prevState) => ({ ...prevState, name: { active: true, message: 'Nombre no puede estar vacio' } }));
      return true;
    }

    if (!activePhenologicalState.iniDate) {
      setError((prevState) => ({ ...prevState, iniDate: { active: true, message: 'Fecha de inicio no puede estar vacia' } }));
      return true;
    }

    if (!activePhenologicalState.endDate) {
      setError((prevState) => ({ ...prevState, endDate: { active: true, message: 'Fecha de inicio no puede estar vacia' } }));
      return true;
    }

    return false;
  };

  const handleAddPhenologicalState = () => {
    const newPhenologicalState = {
      id: uuidv4(),
      name: '',
      iniDate: null,
      endDate: null,
    };
    if (!activePhenologicalState) {
      setActivePhenologicalState(newPhenologicalState);
      return;
    }

    const fieldsCompleteError = activeFieldsCheck();
    if (fieldsCompleteError) {
      return;
    }

    setLocalPhenologicalStates((prevState) => [...prevState, {
      ...activePhenologicalState, disabled: true,
    }]);
    setActivePhenologicalState(newPhenologicalState);
  };

  const removePhenologicalState = (phenologicalStateId: string) => {
    setLocalPhenologicalStates((prevState) => prevState
      .filter((elem) => elem.id !== phenologicalStateId));
  };

  const onValueChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, source: string,
  ) => {
    setActivePhenologicalState((prevState) => ({ ...prevState, [source]: e.target.value }));
    setError((prevState) => ({ ...prevState, [source]: { message: '', active: false } }));
  };

  const handleSubmitUpsert = () => {
    if (speciesName.trim() === '') {
      setError((prevState) => ({ ...prevState, speciesName: { message: 'Debes elegir una especia a asociar', active: true } }));
      return;
    }

    if (varietyName.trim() === '') {
      setError((prevState) => ({ ...prevState, varietyName: { message: 'Nombre no puede estar vacío', active: true } }));
      return;
    }

    const fieldsCompleteError = activeFieldsCheck();
    if (fieldsCompleteError) {
      return;
    }

    handleUpsertFunction({
      name: varietyName,
      species: speciesName,
      phenologicalStates: [...localPhenologicalStates, activePhenologicalState]
        .filter((elem) => elem.name)
        .map((elem) => ({
          iniDate: elem.iniDate as string,
          endDate: elem.endDate as string,
          name: elem.name,
        })),
      id,
    });
  };

  return (
    <Box className={classes.upsertVarietyModalContainer}>
      <Box className={classes.upsertVarietyModalHeader}>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.upsertVarietyModalHeaderTitle}
        >
          <span className={classes.activePageUnderlined}>
            {id ? 'Editar variedad' : 'Agregar variedad'}
          </span>
        </Typography>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider className={classes.dividerStyle} />
      <Box className={classes.upsertVarietyNameSpeciesContainer}>
        <Box width='100%' marginRight='10px'>
          <TextField
            variant='standard'
            label='Especie'
            fullWidth
            select
            required
            error={error.speciesName.active}
            helperText={error.speciesName.active && error.speciesName.message}
            value={speciesName}
            onChange={(e) => {
              setError((prevState) => ({ ...prevState, varietyName: { message: '', active: false } }));
              setSpeciesName(e.target.value);
            }}
          >
            {data?.species?.map((elem) => (
              <MenuItem
                key={elem.id}
                value={elem.name}
              >
                {elem.name}
              </MenuItem>)) ?? []}
          </TextField>
        </Box>
        <Box width='100%' marginLeft='10px'>
          <TextField
            variant='standard'
            label='Nombre'
            fullWidth
            required
            error={error.varietyName.active}
            helperText={error.varietyName.active && error.varietyName.message}
            value={varietyName}
            onChange={(e) => {
              setError((prevState) => ({ ...prevState, varietyName: { message: '', active: false } }));
              setVarietyName(e.target.value);
            }}
          />
        </Box>

      </Box>
      <Box className={classes.upsertVarietyModalHeader}>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.upsertVarietyModalHeaderTitle}
        >
          <span className={classes.activePageUnderlined}>
            Estados fenológicos
          </span>
        </Typography>
        {/* added disabled button to align titles */}
        <IconButton disabled />
      </Box>
      <PerfectScrollbar>
        <TableContainer className={classes.tableScrollbarContainer} >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell}>
                  Nombre Estado
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  Fecha inicio
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  Fecha fin
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {localPhenologicalStates.map((elem) => (
                <PhenologicalStateComponent
                  key={elem.id}
                  elem={elem}
                  onValueChange={onValueChange}
                  removePhenologicalState={removePhenologicalState}
                />
              ))}
              {[activePhenologicalState]
                .filter((elem) => Boolean(elem))
                .map((elem) => (
                  <PhenologicalStateComponent
                    key={elem.id}
                    error={error}
                    elem={elem}
                    onValueChange={onValueChange}
                    removePhenologicalState={removePhenologicalState}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
      <CustomButton color='blue' buttonText='+ Agregar estado fenológico' onClick={handleAddPhenologicalState} />
      <Box className={classes.upsertVarietyModalButtonsContainer}>
        <Box width='100%' mr={2}><CustomButton color='blue' buttonText='Cancelar' onClick={handleCloseModal} /></Box>
        <Box width='100%' ml={2}><CustomButton color='green' buttonText={id ? 'Editar' : 'Agregar'} onClick={() => handleSubmitUpsert()} /></Box>
      </Box>
    </Box>
  );
};

export default UpsertModalVarietyContent;
