import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import CustomScrollableTable from './CustomScrollableTable';
import useStyles from '../styles';
import CustomButton from '../../../../components/General/CustomButton';

const DISPATCH_GUIDE_DETAIL_UI = [
  {
    label: 'ID', key: 'id',
  },
  {
    label: 'Nombre', key: 'name',
  },
  {
    label: 'Serie', key: 'serie',
  },
  {
    label: 'Modelo', key: 'model',
  },
  {
    label: 'Grupo', key: 'group',
  },
  {
    label: 'Subgrupo', key: 'subGroup',
  },
];

type DeviceInformation = {
  id?: string;
  name?: string;
  codProd?: string;
  model?: string;
  group?: string;
  subGroup?: string;
  serie?: string;
};

const CustomDispatchGuideContent = ({
  id,
  dGuideSoftLandCreationDate,
  type,
  dispatchGuideDetailInfo,
  handleCloseModal,
}: {
  id: string,
  dGuideSoftLandCreationDate: string,
  type: string,
  dispatchGuideDetailInfo: DeviceInformation[],
  handleCloseModal: () => void,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.customDispatchGuideContentContainer}>
      <Box className={classes.customDispatchGuideContentHeader}>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.customDispatchGuideContentHeaderTitle}
        >
          <span className={classes.activePageUnderlined}>
            Guía de despacho
          </span>
        </Typography>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box display='flex' flexDirection='column'>
        <Box>
          <Typography
            component='span'
            fontSize='12px'

          >
            <span>ID: </span>
          </Typography>
          <Typography
            component='span'
            fontSize='12px'
            fontWeight='bold'
          >
            {id}
          </Typography>
        </Box>
        <Box>
          <Typography
            component='span'
            fontSize='12px'

          >
            <span>Fecha: </span>
          </Typography>
          <Typography
            component='span'
            fontSize='12px'
            fontWeight='bold'
          >
            {dGuideSoftLandCreationDate}
          </Typography>
        </Box>
        <Box>
          <Typography
            component='span'
            fontSize='12px'
          >
            <span>Tipo: </span>
          </Typography>
          <Typography
            component='span'
            fontSize='12px'
            fontWeight='bold'
          >
            {type}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.customDispatchGuideContentHeader}>
        <Typography
          variant="h4"
          color="primary"
        >
          <span>
            Equipos solicitados
          </span>
        </Typography>
      </Box>
      <CustomScrollableTable
        containerClassName={classes.dispatchGuideContainer}
        headers={DISPATCH_GUIDE_DETAIL_UI}
        rows={{ values: dispatchGuideDetailInfo, count: dispatchGuideDetailInfo.length }}
        noElementsMessage='No existen equipos asociados a esta guía de despacho'
      />
      <Box mt={2} >
        <CustomButton buttonText='Volver' onClick={handleCloseModal} color='blue' />
      </Box>
    </Box>
  );
};

export default CustomDispatchGuideContent;
