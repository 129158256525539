/* General Components Styling */

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const extraTablePadding = '20px';

const useStyles = makeStyles((theme: Theme) => ({
  // General
  extraPadding: {
    paddingLeft: extraTablePadding,
    paddingRight: extraTablePadding,
  },
  // CustomTable
  tableHeaderText: {
    fontWeight: 'bold',
  },
  // CustomSearch
  customSearchPositioning: {
    padding: '16px 20px',
  },
  // CustomHeader
  activePageUnderlined: {
    borderBottom: '2px solid',
    borderBottomColor: theme.palette.secondary.main,
  },
  // CustomBUtton
  greenButton: {
    backgroundColor: '#54B03A',
    '&:hover': {
      backgroundColor: '#438c2e',
      color: '#e6e8e6',
    },
  },
  blueButton: {
    backgroundColor: theme.palette.primary.main,
  },
  lightBlueButton: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: '#026eba',
    },
  },
  // CustomModal
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  // Custom Delete Modal
  deleteModalContainer: {
    width: '500px',
    display: 'flex',
    flexDirection: 'column',
  },
  deleteModalHeader: {
    display: 'flex',
    width: '100%',
    marginTop: '12px',
  },
  deleteModalHeaderTitle: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dividerStyle: {
    margin: '24px 0px',
  },
  deleteModalButtonsContainer: {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'space-between',
  },

  // Custom Confirmation Modal
  dialogContent: {
    textAlign: 'center',
  },
  dialogContentText: {
    fontSize: '16px',
    color: 'rgba(57, 70, 78, 1)',
    letterSpacing: '0.15px',
    lineHeight: '24px',
  },
  dialogActions: {
    justifyContent: 'center',
    padding: '16px 0px',
    margin: '20px 0px',
    gap: '15px',
  },
  // Custom styles for the title
  dialogTitle: {
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: '500',
    color: 'rgba(57, 70, 78, 1)',
  },
  // Blue divider styling
  blueDivider: {
    width: '30%',
    height: '2px',
    backgroundColor: theme.palette.secondary.main,
    marginBottom: '16px',
    alignSelf: 'center',
  },
  // Gray divider styling
  grayDivider: {
    width: '90%',
    height: '1px',
    backgroundColor: theme.palette.grey[300],
    margin: '12px 0',
    alignSelf: 'center',
  },
  // Custom buttons
  cancelButton: {
    height: '42px',
    padding: '8px 22px 8px 22px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    width: '100%',
  },
  confirmButton: {
    height: '42px',
    padding: '8px 22px 8px 22px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    width: '100%',
  },
  deleteButton: {
    height: '42px',
    padding: '8px 22px 8px 22px',
    backgroundColor: 'rgba(253, 30, 74, 1)',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#c62828',
    },
    width: '100%',
  },
  // Close button styling
  closeButton: {
    color: theme.palette.grey[500],
  },

  // FullScreen Image Modal

  fullScreenModal: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
  },
  fullScreenModalContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  fullScreenImage: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

export default useStyles;
