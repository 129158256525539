import React from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box, Divider, IconButton, Typography,
} from '@mui/material';

import CustomButton from './CustomButton';
import useStyles from './styles';

const CustomDeleteModalContent = ({
  id, message, title, handleCloseModal, handleDeleteFunction,
}: {
  handleCloseModal: VoidFunction,
  handleDeleteFunction: ({ id }: {
    id: string,
  }) => void,
  message: string,
  title: string,
  id?: string,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.deleteModalContainer}>
      <Box className={classes.deleteModalHeader}>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.deleteModalHeaderTitle}
        >
          <span className={classes.activePageUnderlined}>
            {title}
          </span>
        </Typography>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider className={classes.dividerStyle} />
      <Box>
        <Typography
          variant='body1'
        >
          {message}
        </Typography>
      </Box>
      <Box className={classes.deleteModalButtonsContainer}>
        <Box width='100%' mr={2}><CustomButton color='blue' buttonText='Cancelar' onClick={handleCloseModal} /></Box>
        <Box width='100%' ml={2}><CustomButton color='green' buttonText='Eliminar' onClick={() => handleDeleteFunction({ id: id as string })} /></Box>
      </Box>
    </Box>
  );
};

export default CustomDeleteModalContent;
