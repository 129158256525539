import { CultivationCyclePopulatedSchema } from '../../../requests/api/apiTypes';

export const FIELDS_EQ = {
  name: 'Nombre',
  varietyId: 'Variedad',
  startDate: 'Fecha de inicio',
  endDate: 'Fecha de fin',
};

export const REQUIRED_UPSERT_CULT_CYCLE = ['name', 'startDate', 'endDate', 'varietyId'];

export type RouteParams = {
  id: string;
};

export const SECTOR_LIST_UI = [
  {
    label: 'Nombre', key: 'name',
  },
  {
    label: 'Fecha inicio', key: 'startingDate',
  },
  {
    label: 'Fecha fin', key: 'endingDate',
  },
  {
    label: 'Especie', key: 'specie',
  },
  {
    label: 'Variedad', key: 'variety',
  },
  {
    label: 'Acciones', key: 'actions',
  },
];

export const CULT_CYCLE_LIST_UI = [
  {
    label: 'Estado', key: 'name',
  },
  {
    label: 'Fecha inicio', key: 'startingDate',
  },
  {
    label: 'Fecha fin', key: 'endingDate',
  },
  {
    label: 'Acciones', key: 'actions',
  },
];

export const REQUIRED_UPDATE_SECTOR_FIELDS = [
  'name',
  'theoreticalSurface',
  'typeOfSoil',
];

export const OPTIONAL_UPDATE_SECTOR_FIELDS = [
  'typeOfIrrigation',
  'variety',
  'plantingYear',
  'emitterFlowRate',
  'transmittersDistance',
  'plantingFrame',
  'managerName',
  'parentSector',
];

export const REQUIRED_UPDATE_SECTOR_EQ: Record<string, string> = {
  name: 'Nombre',
  theoreticalSurface: 'Superficie teórica',
  typeOfSoil: 'Tipo de suelo',
};

export type SimplifiedCultivationCycle = Pick<CultivationCyclePopulatedSchema, 'id' | 'name' | 'startDate' | 'endDate'> & {
  varietyName: string;
  varietySpecies: string;
};

export interface UpsertCultivationCycleInput {
  name: string;
  startDate: string;
  endDate: string;
  varietyId: string;
}

export type KMZPolygons = {
  id?: string | undefined;
  coordinates?: { lat: number; lng: number; }[] | undefined;
  isSelected?: boolean | undefined;
  isLinked?: boolean | undefined;
  onPolygonClick?: VoidFunction | undefined;
}[];
