import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  activePageUnderlined: {
    borderBottom: '2px solid',
    borderBottomColor: theme.palette.secondary.main,
  },
  containerContainer: {
    height: '100%',
    flexDirection: 'column',
    paddingBottom: '24px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  mainBoxContainer: {
    display: 'flex',
    width: '100%',
    paddingTop: '10px',
    gap: '24px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  box: {
    flex: 1,
  },
  divider: {
    margin: '5px 0px',
    width: '100%',
  },
  imagesDivider: {
    marginBottom: theme.spacing(2),
    backgroundColor: 'rgba(170, 170, 170, 1)',
  },

  // devicesInfoComponent
  cardLayoutContainer: {
    display: 'flex',
    flex: '1',
    boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.1)',
  },
  cardLayoutContentContainer: {
    width: '100%',
  },
  boxContentContainer: {
    flexDirection: 'column',
    gap: '10px',
  },

  // imagesComponent
  cardImagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  cardImagesContentContainer: {
    width: '100%',
    textAlign: 'center',
  },
  imagesHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  imageMainContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  mainImage: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: theme.shape.borderRadius,
  },
  navButtonLeft: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    left: theme.spacing(2),
  },
  navButtonRight: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    right: theme.spacing(2),
  },
  deleteButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  addImageButton: {
    cursor: 'pointer',
  },
  thumbnailContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(1),
  },
  thumbnail: {
    width: 70,
    height: 70,
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
  },
  activeThumbnail: {
    border: '4px solid rgba(54, 147, 213, 1)',
  },

}));

export default useStyles;
