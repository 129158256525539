/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import { FarmSchema } from '../../../../requests/api/apiTypes';

type FormattedSelectInformation = {
  id: string,
  name: string,
  lat: number,
  lng: number,
};
interface GooglePlacesAutocompleteProps {
  error: FarmSchema;
  setError: React.Dispatch<React.SetStateAction<FarmSchema>>;
  setFarmInformation: React.Dispatch<React.SetStateAction<FarmSchema>>;
}

const GoogleAutocomplete: React.FC<GooglePlacesAutocompleteProps> = ({
  error,
  setError,
  setFarmInformation,
}) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 500,
  });

  const [options, setOptions] = useState<FormattedSelectInformation[]>([]);

  useEffect(() => {
    if (!ready) return;

    const fetchOptions = async () => {
      if (status === 'OK') {
        const results: FormattedSelectInformation[] = await Promise.all(
          data.map(async (elem) => {
            const coordinates = await getGeocode({ address: elem.description });
            const { lat, lng } = getLatLng(coordinates[0]);
            return {
              id: elem.place_id,
              name: elem.description,
              lat,
              lng,
            };
          }),
        );
        setOptions(results);
      }
    };

    fetchOptions();
  }, [ready, status, data]);

  const handleSelection = (
    e: React.SyntheticEvent,
    selectedValue: FormattedSelectInformation | string | null,
  ) => {
    if (selectedValue && typeof selectedValue !== 'string') {
      setFarmInformation((prevState) => ({
        ...prevState,
        location: selectedValue.name,
        center: {
          lat: `${selectedValue.lat}`,
          lng: `${selectedValue.lng}`,
        },
      }));
    }

    setError((prevState) => ({
      ...prevState,
      location: '',
      center: {
        lat: '',
        lng: '',
      },
    }));

    clearSuggestions();
  };

  const onInputChange = (
    e: React.SyntheticEvent,
    newValue: string,
  ) => {
    setValue(newValue);
    setFarmInformation((prevState) => ({
      ...prevState,
      location: newValue,
    }));
    setError((prevState) => ({
      ...prevState,
      location: '',
      center: {
        lat: '',
        lng: '',
      },
    }));
  };

  return (
    <Autocomplete
      fullWidth
      id="google-autocomplete-input"
      options={options}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
      onBlur={clearSuggestions}
      freeSolo
      value={value}
      onChange={handleSelection}
      onInputChange={onInputChange}
      renderInput={(params) => <TextField
        label="Ubicación"
        variant="standard"
        error={error.location !== ''}
        helperText={error.location !== '' && error.location}
        {...params}
      />}
    />
  );
};

export default GoogleAutocomplete;
