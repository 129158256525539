import React, { ChangeEvent, useState } from 'react';
import {
  Box, Divider, Grid, IconButton, TextField, Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import useStyles from '../styles';

import { PhenologicalState } from '../../../../../requests/api/apiTypes';
import CustomButton from '../../../../../components/General/CustomButton';

const UpsertPhenologicalStateModalContent = ({
  handleCloseModal,
  handleUpsertFunction,
  id,
} : {
  handleCloseModal: VoidFunction,
  handleUpsertFunction: (PhenologicalState: PhenologicalState) => void,
  id?: string
}) => {
  const classes = useStyles();

  const [newPhenologicalState, setNewPhenologicalState] = useState<PhenologicalState>({
    name: '',
    startDate: '',
    endDate: '',
  });

  const handleDataChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name !== 'name') {
      setNewPhenologicalState({
        ...newPhenologicalState,
        [name]: value ? new Date(value).toISOString().split('T')[0] : null,
      });
    } else {
      setNewPhenologicalState({
        ...newPhenologicalState,
        [name]: value ?? null,
      });
    }
  };

  const handleUpsertPhenologicalState = () => {
    handleUpsertFunction({
      name: newPhenologicalState.name,
      startDate: newPhenologicalState.startDate,
      endDate: newPhenologicalState.endDate,
    });
  };

  return (
    <Box className={classes.upsertCropCycleModalContainer}>
      <Box className={classes.upsertCropCycleModalHeader}>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.upsertCropCycleModalHeaderTitle}
        >
          <span className={classes.activePageUnderlined}>
            Agregar estado fenológico
          </span>
        </Typography>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider className={classes.dividerStyle} />
      <Grid container spacing={2}>
        <Grid item sm={12} md={12}>
          <TextField
            label="Nombre"
            name="name"
            value={newPhenologicalState.name}
            onChange={handleDataChange}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item sm={6} md={6}>
          <TextField
            label="Fecha de inicio"
            type="date"
            name="startDate"
            value={newPhenologicalState.startDate}
            onChange={handleDataChange}
            fullWidth variant="standard"
            InputLabelProps={{ shrink: true }} />
        </Grid>
        <Grid item sm={6} md={6}>
          <TextField
            label="Fecha de fin"
            type="date"
            name="endDate"
            value={newPhenologicalState.endDate}
            onChange={handleDataChange}
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }} />
        </Grid>
      </Grid>
      <Box className={classes.upsertCropCycleModalButtonsContainer}>
        <Box width='100%' mr={2}><CustomButton color='blue' buttonText='Cancelar' onClick={handleCloseModal} /></Box>
        <Box width='100%' ml={2}><CustomButton color='green' buttonText={id ? 'Editar' : 'Agregar'} onClick={handleUpsertPhenologicalState} /></Box>
      </Box>
    </Box>
  );
};

export default UpsertPhenologicalStateModalContent;
