import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ListLayout from '../ListLayout';
import useStyles from '../../styles';
import ListTile from '../ListTile';
import CustomButton from '../../../../../components/General/CustomButton';
import AddDevicesFlow from './AddDevicesFlow';
import { ReduxState } from '../../../../../types';
import useFetchApi from '../../../../../hooks/useFetchApi';
import { GetDevicesByFarmIdResponse } from '../../../../../requests/api/apiTypes';
import { setAllFarmDevices, setFarmDevice } from '../../../../../actions/farmSectorActions';
import { filteredDevices } from '../../helpers';

const DevicesList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openAddDevicesModal, setOpenAddDevicesModal] = useState(false);
  const { selectedFarm } = useSelector((state: ReduxState) => state.accountFarm);
  const {
    selectedDevice,
    sensorNodeFilter,
    allFarmDevices,
  } = useSelector((state: ReduxState) => state.farmSector);
  const history = useHistory();

  const validFarmId = selectedFarm?.id ?? undefined;

  const { data } = useFetchApi<GetDevicesByFarmIdResponse>({
    modelEndpoint: 'farmsDetail',
    modelName: 'devices',
    id: validFarmId || '',
    dependencies: [validFarmId ?? ''],
  });

  useEffect(() => {
    if (data?.devices) {
      dispatch(setAllFarmDevices(data.devices));
    }
  }, [data?.devices]);

  return (
    <>
      <ListLayout>
        <Typography
          variant="h3"
          color="primary"
        >
          Equipos
        </Typography>
        <Divider className={classes.divider} />
        {selectedFarm
          && <>
            <Box marginBottom='12px' marginTop="12px" width='100%'>
              <CustomButton buttonText='AGREGAR EQUIPO' onClick={() => setOpenAddDevicesModal(true)} color='blue' />
            </Box>
            <Box className={classes.listTilesContainer}>
              {(selectedFarm && allFarmDevices && allFarmDevices.length > 0)
                && filteredDevices(allFarmDevices, sensorNodeFilter)
                  .map((elem) => {
                    const { id, deviceModel } = elem;
                    return (

                      <React.Fragment key={id}>
                        <ListTile
                          key={id}
                          title={deviceModel?.name}
                          tileInformation={{
                            Código: deviceModel?.codProd ?? 'Sin código',
                            // Tipo: deviceModel?.group,
                            // Marca: rest.brand,
                            // Modelo: rest.model,
                            // Estado: rest.status,
                            'ID nodo': elem.nodeId ?? '',
                            // 'N° subsectores': rest.numOfSectors,
                          }}
                          isActive={selectedDevice === id}
                          onClick={() => dispatch(setFarmDevice(id ?? ''))}
                          customContainerClassName={classes.actionableListTileContainer}
                          seeMore={() => history.push(`/layout/equipos/${id}`)}
                        />
                        <Divider />
                      </React.Fragment>
                    );
                  })}
              {(selectedFarm && !(allFarmDevices && allFarmDevices.length > 0)) && <Typography variant='body2'>
                No hay equipos asociados a este campo
              </Typography>}
            </Box>
          </>}
        {!selectedFarm && <Typography variant='body2'>
          Elige un campo para ver los equipos asociados
        </Typography>}

      </ListLayout>
      <AddDevicesFlow
        isModalOpen={openAddDevicesModal}
        setModalOpen={setOpenAddDevicesModal}
      />
    </>

  );
};

export default DevicesList;
