/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Box, Divider, MenuItem, TextField, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ListLayout from '../ListLayout';
import useStyles from '../../styles';
import CustomButton from '../../../../../components/General/CustomButton';
import ListTile from '../ListTile';
import AddFarmFlow from '../Farm/AddFarmFlow';
import { ReduxState } from '../../../../../types';
import AddSectorFlow from './AddSectorFlow';
import { setAccountFarm } from '../../../../../actions/accountFarmAction';
import { FarmSchema } from '../../../../../requests/api/apiTypes';
import { setFarmSector } from '../../../../../actions/farmSectorActions';

const SectorsList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  // const { selectedAccount } = useSelector((state: ReduxState) => state.userAccount);
  const { selectedFarm, farmsOptions } = useSelector((state: ReduxState) => state.accountFarm);
  const { selectedSector } = useSelector((state: ReduxState) => state.farmSector);
  const [openAddSectorModal, setOpenAddSectorModal] = useState(false);
  const [openNewFarmFlow, setOpenNewFarmFlow] = useState(false);

  const handleFarmValueChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (farmsOptions && e.target.value !== '0') {
      const auxFarm = farmsOptions
        .find((elem) => elem.id === e.target.value);
      dispatch(setAccountFarm(auxFarm as FarmSchema));
    }
  };

  return (
    <>
      <ListLayout>
        <Typography
          variant="h3"
          color="primary"
        >
          Sectores
        </Typography>
        <Divider className={classes.divider} />
        <TextField
          value={selectedFarm?.id || ''}
          onChange={handleFarmValueChange}
          variant='standard'
          fullWidth
          select
          label='Campo'
          sx={{ marginBottom: '5px' }}
        >
          {farmsOptions
            ?.map((elem) => (<MenuItem key={elem.id} value={elem.id}>
              {elem.name}
            </MenuItem>))}
          <Divider className={classes.divider} />
          <MenuItem value={'0'} onClick={() => setOpenNewFarmFlow(true)}>
            Crear nuevo campo
          </MenuItem>
        </TextField>
        {selectedFarm && <>
          <ListTile
            tileInformation={{
              ID: selectedFarm?.id ?? '',
              Nombre: selectedFarm?.name as string,
              Ubicación: selectedFarm?.location as string,
              'N° Sectores': selectedFarm?.sectors?.length ?? 0,
            }}
          />
          <Divider className={classes.divider} />
          <Box marginBottom='12px' marginTop="12px" width='100%'>
            <CustomButton buttonText='AGREGAR SECTOR' onClick={() => setOpenAddSectorModal(true)} color='blue' />
          </Box>
        </>}
        {!selectedFarm && <Typography variant='body2'>
          Selecciona un campo para más información
        </Typography>}
        <Box className={classes.listTilesContainer}>
          {(selectedFarm?.sectors && selectedFarm.sectors.length > 0)
            && selectedFarm.sectors.map((sector) => {
              const {
                id, name, subSectors, ...rest
              } = sector;
              return (
                <React.Fragment key={sector.cdtecSectorId}>
                  <ListTile
                    title={name}
                    tileInformation={{
                      ID: rest.cdtecSectorId,
                      'Superficie Teórica': rest.theoreticalSurface,
                      // 'Superficie Real': rest.realSurface,
                      'Tipo de suelo': rest.typeOfSoil,
                    }}
                    isActive={selectedSector === id}
                    onClick={() => dispatch(setFarmSector(id ?? ''))}
                    customContainerClassName={classes.actionableListTileContainer}
                    seeMore={() => history.push(`/layout/sectores/${id}`)}
                  />
                  <Divider />
                </React.Fragment>

              );
            })}
          {(selectedFarm?.sectors && selectedFarm.sectors.length === 0)
            && <Typography variant='body2'>
              No hay sectores asociados a este campo
            </Typography>}
        </Box>
      </ListLayout>
      <AddSectorFlow
        isModalOpen={openAddSectorModal}
        setOpenModal={setOpenAddSectorModal}
        lat={selectedFarm?.center.lat ?? ''}
        lng={selectedFarm?.center.lng ?? ''}
      />
      <AddFarmFlow
        isModalOpen={openNewFarmFlow}
        setOpenModal={setOpenNewFarmFlow}
      />
    </>
  );
};

export default SectorsList;
