import React, { useState } from 'react';
import {
  Box,
  Container,
  Tabs,
  Tab,
} from '@mui/material';
import { useParams } from 'react-router';
import Page from '../../../../components/Layout/Page';
import useStyles from './styles';
import CustomHeader from '../../../../components/General/CustomHeader';
import ImagesContainer from './component/ImagesContainer';
import DeviceInfo from './component/DeviceInfo';
import useFetchApi from '../../../../hooks/useFetchApi';
import { GetDeviceByIdResponse } from '../../../../requests/api/apiTypes';

const DevicesView = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState('instalacion');
  const { id: deviceId } = useParams<{ id: string }>();

  const { data: fetchData } = useFetchApi<GetDeviceByIdResponse>({
    modelEndpoint: 'devicesDetail',
    modelName: 'devices',
    id: deviceId,
  });

  const localData = {
    deviceName: fetchData?.device?.deviceModel?.name,
    installationDate: fetchData?.device?.createdAt,
    status: fetchData?.device?.status,
    deviceId: fetchData?.device?.id,
    comment: fetchData?.device?.comment,
  };

  return (
    <Page
      className={classes.root}
      title='Layout'
    >
      <Container maxWidth={false} className={classes.containerContainer}>
        <CustomHeader
          title={`Layout / ${localData.deviceName}`}
          TabsComponent={
            <Tabs
              value={activeTab}
              onChange={(e, value) => setActiveTab(value)}
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab value="graficos" label="Gráficos" />
              <Tab value="instalacion" label="Instalación" />
            </Tabs>
          }
        />
        <Box display='flex' className={classes.mainBoxContainer}>
          {activeTab === 'instalacion'
            ? <>
              <Box className={classes.box}>
                {localData.deviceId ? (
                  <ImagesContainer
                    deviceId={localData.deviceId}
                  />
                ) : < div>Loading device...</div>
                }
              </Box>
              <Box className={classes.box}>
                {localData.deviceId && localData.installationDate && localData.status ? (
                  <DeviceInfo
                    deviceId={localData.deviceId}
                    installationDate={localData.installationDate}
                    status={localData.status}
                    comment={localData.comment}
                  />
                ) : (
                  <div>Loading...</div>
                )}
              </Box>
            </>
            : <>
              <h1>Graficos</h1>
            </>
          }
        </Box>
      </Container>
    </Page>
  );
};

export default DevicesView;
