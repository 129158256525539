import JSZip from 'jszip';
import { DeviceSchema, PutSectorsKMZSchema } from '../../../requests/api/apiTypes';
import { SensorNode } from '../../../types';

const filteredDevices = (devices: DeviceSchema[], sensorNodeFilter: SensorNode) => devices
  .filter((device) => device.deviceType === sensorNodeFilter.deviceType)
  .filter((device) => {
    if (sensorNodeFilter.filterValue !== '' && device.deviceType === 'sensor') {
      return device.environmentalFactor;
    } if (sensorNodeFilter.filterValue !== '' && device.deviceType === 'node') {
      if (sensorNodeFilter.filterValue === 'gateway') {
        return device.isGatewayNode;
      }
      return !device.isGatewayNode;
    }
    return device;
  });

const parseCoordinates = (coordinatesText: string) => {
  const coordinatePairs = coordinatesText.split(/\s+/);
  return coordinatePairs.map((pair: string) => {
    const [lng, lat] = pair.split(',').map(Number);
    return { lat, lng };
  });
};

const parseKML = (kmlText: string) => {
  const parser = new DOMParser();
  const kmlDoc = parser.parseFromString(kmlText, 'application/xml');

  // Extract Placemark elements which can contain polygons
  const placemarks = kmlDoc.getElementsByTagName('Placemark');
  const extractedPolygons = [];

  for (let i = 0; i < placemarks.length; i += 1) {
    const polygon = placemarks[i].getElementsByTagName('Polygon')[0];
    if (polygon) {
      const coordinates = polygon.getElementsByTagName('coordinates')[0].textContent?.trim();
      if (!coordinates) {
        throw new Error('Coordenadas inválidas');
      }
      const parsedCoordinates = parseCoordinates(coordinates);
      extractedPolygons.push(parsedCoordinates);
    }
  }

  return extractedPolygons;
};

const handleKMZFile = async (
  file: File,
  setKMZSector: React.Dispatch<React.SetStateAction<PutSectorsKMZSchema[string] | null>>,
) => {
  const zip = new JSZip();
  const content = await zip.loadAsync(file);
  const kmlFile = content.file(/\.kml$/i)[0];
  const kmlText = await kmlFile.async('text');

  const rawPolygons = parseKML(kmlText);

  return rawPolygons.map((rPolygon, index) => ({
    id: index.toString(),
    coordinates: rPolygon,
    onPolygonClick: () => setKMZSector({ coordinates: rPolygon, id: index.toString() }),
  }));
};

export {
  filteredDevices,
  handleKMZFile,
};
