import React, { ChangeEvent, useState, useEffect } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box, Checkbox, Divider, FormControlLabel, Grid, IconButton, MenuItem, TextField, Typography,
} from '@mui/material';
import useStyles from '../styles';
import CustomButton from '../../../../../components/General/CustomButton';
import { VarietySchema } from '../../../../../requests/api/apiTypes';
import { SimplifiedCultivationCycle, UpsertCultivationCycleInput } from '../../types';

const UpsertCultivationCycleModalContent = ({
  handleCloseModal,
  handleUpsertFunction,
  varietiesList,
  cycleId,
  cultivationCycle,
}: {
  handleCloseModal: VoidFunction,
  handleUpsertFunction: (cultivationCycle: UpsertCultivationCycleInput) => void,
  varietiesList: VarietySchema[],
  cycleId?: string,
  cultivationCycle?: SimplifiedCultivationCycle,
}) => {
  const classes = useStyles();

  const [currentCultivationCycle, setCurrentCultivationCycle] = useState<
  UpsertCultivationCycleInput>({
    name: '',
    startDate: '',
    endDate: '',
    varietyId: '',
  });

  const [selectedSpecie, setSelectedSpecie] = useState('');
  const [varietiesOptions, setVarietiesOptions] = useState<VarietySchema[]>([]);

  useEffect(() => {
    if (cycleId && cultivationCycle) {
      const currentVariety = varietiesList.find((variety) => (
        variety.name === cultivationCycle.varietyName
        && variety.species === cultivationCycle.varietySpecies
      ));
      setCurrentCultivationCycle({
        name: cultivationCycle.name,
        startDate: cultivationCycle.startDate.split('T')[0],
        endDate: cultivationCycle.endDate.split('T')[0],
        varietyId: currentVariety?.id ?? '',
      });

      setSelectedSpecie(currentVariety?.species || '');
      setVarietiesOptions(
        varietiesList.filter((variety) => variety.species === currentVariety?.species) || [],
      );
    }
  }, [cycleId, cultivationCycle, varietiesList]);

  const handleSpecieChange = (e: ChangeEvent<HTMLInputElement>) => {
    const specie = e.target.value;
    setSelectedSpecie(specie);
    const filteredVarieties = varietiesList.filter((option) => option.species === specie);
    setVarietiesOptions(filteredVarieties);
    setCurrentCultivationCycle({
      ...currentCultivationCycle,
      varietyId: '',
    });
  };

  const handleVarietyChange = (e: ChangeEvent<HTMLInputElement>) => {
    const varietyId = e.target.value;
    setCurrentCultivationCycle({
      ...currentCultivationCycle,
      varietyId,
    });
  };

  const handleUpsertCultivationCycle = () => {
    handleUpsertFunction({
      name: currentCultivationCycle.name,
      startDate: currentCultivationCycle.startDate,
      endDate: currentCultivationCycle.endDate,
      varietyId: currentCultivationCycle.varietyId,
    });
  };

  const handleDataChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name !== 'name') {
      setCurrentCultivationCycle({
        ...currentCultivationCycle,
        [name]: value ? new Date(value).toISOString().split('T')[0] : null,
      });
    } else {
      setCurrentCultivationCycle({
        ...currentCultivationCycle,
        [name]: value ?? null,
      });
    }
  };

  return (
    <Box className={classes.upsertCropCycleModalContainer}>
      <Box className={classes.upsertCropCycleModalHeader}>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.upsertCropCycleModalHeaderTitle}
        >
          <span className={classes.activePageUnderlined}>
            Agregar ciclo de cultivo
          </span>
        </Typography>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider className={classes.dividerStyle} />
      <Grid container spacing={2}>
        <Grid item sm={12} md={12}>
          <TextField
            label="Nombre"
            name="name"
            value={currentCultivationCycle.name}
            onChange={handleDataChange}
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }} />
        </Grid>
        <Grid item sm={6} md={6}>
          <TextField
            label="Fecha de inicio"
            type="date"
            name="startDate"
            value={currentCultivationCycle.startDate}
            onChange={handleDataChange}
            fullWidth variant="standard"
            InputLabelProps={{ shrink: true }} />
        </Grid>
        <Grid item sm={6} md={6}>
          <TextField
            label="Fecha de fin"
            type="date"
            name="endDate"
            value={currentCultivationCycle.endDate}
            onChange={handleDataChange}
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }} />
        </Grid>

        <Grid item sm={6} md={6}>
          <TextField
            label="Especie"
            select
            fullWidth
            value={selectedSpecie}
            onChange={handleSpecieChange}
            variant="standard"
          >
            {[...new Set(varietiesList.map((option) => option.species))].map((specie) => (
              <MenuItem key={specie} value={specie}>
                {specie}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={6} md={6}>
          <TextField
            label="Variedad"
            select
            fullWidth
            value={currentCultivationCycle.varietyId}
            onChange={handleVarietyChange}
            variant="standard"
            disabled={!selectedSpecie}
          >
            {varietiesOptions.map((variety) => (
              <MenuItem key={variety.id} value={variety.id}>
                {variety.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item>
          <FormControlLabel control={<Checkbox name="useStandardPhenologicalStates" />} label="Utilizar estados fenológicos estándar" />
        </Grid>
      </Grid>
      <Box className={classes.upsertCropCycleModalButtonsContainer}>
        <Box width='100%' mr={2}><CustomButton color='blue' buttonText='Cancelar' onClick={handleCloseModal} /></Box>
        <Box width='100%' ml={2}><CustomButton color='green' buttonText={cycleId ? 'Editar' : 'Agregar'} onClick={handleUpsertCultivationCycle} /></Box>
      </Box>
    </Box>
  );
};

export default UpsertCultivationCycleModalContent;
